import {
    CALCULATOR_DIGITAL,
    CALCULATOR_PROVIDER,
    CALCULATOR_PHYSICAL,
    CALCULATOR_SETTING,
    EDIT_CALCULATOR, SET_CALCULATOR_VAL
} from "../constants/calculatorConstant"

export const calculatorReducer = (state = {}, action) => {
    switch (action.type) {
        case CALCULATOR_SETTING :
            return {...state, settings: {...state.settings, [action.payload.name]: action.payload.value}}
        case CALCULATOR_PROVIDER :
            return {...state, provider: {...state.provider, [action.payload.name]: action.payload.value}}
        case CALCULATOR_DIGITAL :
            return {...state, digital: {...state.digital, [action.payload.name]: action.payload.value}}
        case CALCULATOR_PHYSICAL :
            return {...state, physical: {...state.physical, [action.payload.name]: action.payload.value}}
        case EDIT_CALCULATOR :
            return {
                settings: {
                    data: {},
                    emissions: {},
                    name: null
                },
                physical: {},
                digital: {},
                provider: {},
                editCalculator: action.payload
            }
        case SET_CALCULATOR_VAL :
            return action.payload
        default:
            return state
    }
}