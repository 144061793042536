import {resolve} from "../../hook/resolver";
import axios from "axios";
import {GET_ALLFURNITURE, CREATE_FURNITURE, UPDATE_FURNITURE, DELETE_FURNITURE} from "../../constants/apiService";

export const createFurniture = async (token, data) => {
    return await resolve(axios.post(CREATE_FURNITURE, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const updateFurniture = async (token, data) => {
    return await resolve(axios.put(UPDATE_FURNITURE, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const deleteFurniture = async (token, data) => {
    return await resolve(axios.delete(DELETE_FURNITURE + "/" + data.id, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}