import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom"
import React, {lazy, Suspense, useEffect, useState} from 'react'
import {IsUserRedirect, ProtectedRoute} from "./components/shared/Route"
import {
    ADMINISTRATOR_ROUTE,
    CALCULATOR_ROUTE,
    CONTACT_ROUTE,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    PROFILE_ROUTE,
    EVENT_LIST_PATH,
    REGISTER_ROUTE,
    LOGOUT_ROUTE,
    FORGOT_ROUTE,
    FAQ_ROUTE,
    RESET_ROUTE,
    EVENT_ROUTE,
    SERVICE_ROUTE,
    INVOICES_ROUTE,
    OFFERS_ROUTE,
    COMPARISON_ROUTE,
    BILLING_SUCCESS_ROUTE,
    BILLING_FAILURE_ROUTE,
    SUBSCRIPTION_ROUTE,
    ELEARNING_ROUTE,
    MODULE_GET_DETAIL,
    EVAL_QCM,
    DOC_GET_DETAIL_ADMIN,
    DOC_GET_DETAIL_USER,
    INVITATION_ROUTE,
    PROVIDER_LIST_ROUTE,
    CATALOGUE_ROUTE,
    TYPE_LIST,
    INGREDIENTS_ROUTE,
    PROVIDER_SERVICE_ROUTE,
    PROVIDER_SERVICE_SETTINGS,
    ACCEPT_INVITATION_ROUTE,
    NEW_CALCULATOR_ROUTE
} from "./constants/appRoute"

import {I18nextProvider, useTranslation} from "react-i18next";
import i18next from "i18next";

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import {useSelector} from "react-redux";
import {TopBar} from "./components/topbar";
import {ToastContainer} from "react-toastify";
import {getComplementaryColor, IniBackgroundColor} from "./actions/backgroundColor";
import Catalogue from "./pages/catalogue/catalogue";
import Ingredients from "./pages/ingredients/ingredients";
import {updateType} from "./utils/api/PieceType";
import {getMaintenanceMode} from "./utils/api/security";
import AcceptInvitationPage from "./pages/AcceptInvitationPage";
import Loading from "./components/loading/Loading"
import { NEW_APP_URL } from "./constants/apiEndPoint";

// import  { Redirect } from 'react-router-dom';


//Page
const RegisterPage = lazy(() => import("./pages/security/RegisterPage"))
const LoginPage = lazy(() => import("./pages/security/LoginPage"))
const ProfilePage = lazy(() => import("./pages/profile/ProfilePage"))
const InvitationPage = lazy(() => import("./pages/InvitationPage"))
const ProviderListPage = lazy(() => import("./pages/ProviderListPage"))
const TypeListPage = lazy(() => import("./pages/TypeListPage"))
const HomePage = lazy(() => import("./pages/home/HomePage"))
const Maintenance = lazy(() => import("./pages/Maintenance"))
const MaintenanceNotice = lazy(() => import("./pages/MaintenanceNotice"))
const DashBoardPage = lazy(() => import("./pages/dashboard/DashBoardPage"))
const EventPreviewPage = lazy(() => import("./pages/calculator/EventPreview"))
const ServicePreviewPage = lazy(() => import("./pages/calculator/ServicePreview"))
const EventListPage = lazy(() => import("./pages/event/EventListPage"))
const ContactPage = lazy(() => import("./pages/contact/ContactPage"))
const CalculatorPage = lazy(() => import("./pages/calculator/CalculatorPage"))
const AdministratorPage = lazy(() => import("./pages/administrator/AdministratorPage"))
const LogoutFunction = lazy(() => import("./pages/security/LogoutFunction"))
const FaqPage = lazy(() => import("./pages/FaqPage"))
const ForgotPassword = lazy(() => import("./pages/security/ForgotPassword"))
const ResetPassword = lazy(() => import("./pages/security/ResetPassword"))
const InvoicesPage = lazy(() => import("./pages/invoices/InvoicesPage"))
const OffersPage = lazy(() => import("./pages/offers/OffersPage"))
const BillingSuccessPage = lazy(() => import("./pages/billing/BillingSuccessPage"))
const BillingFailurePage = lazy(() => import("./pages/billing/BillingFailurePage"))
const ModuleListPage = lazy(() => import("./pages/elearning/moduleList"))
const AdminQCM = lazy(() => import("./pages/elearning/adminQCM"))
const ListDocs = lazy(() => import("./pages/elearning/listDocs"))
const EvalQCM = lazy(() => import("./pages/elearning/evalQCM"))
const ScenarioComparison = lazy(() => import("./pages/comparison/ScenarioComparison"))
const catalogue = lazy(() => import("./pages/catalogue/catalogue"))
const ProviderServiceList = lazy(() => import("./pages/providersServices/ProviderServiceList"))
const ProviderServiceSetting = lazy(() => import("./pages/providersServices/Settings"))

//Error
const NotFoundPage = lazy(() => import("./pages/error/NotFoundPage"))


function App() {

    const [openSlide, toggleSlide] = useState(true);
    const {language} = useSelector(state => state.language)
    const {user} = useSelector(state => state.security)
    const [maintenance, setMaintenance] = useState(false);
    const [maintenanceNotice, setMaintenanceNotice] = useState(false);


    i18next.init({
        interpolation: {escapeValue: false},
        lng: language,
        resources: {
            en: {
                common: common_en
            },
            fr: {
                common: common_fr
            },
        },
    });

    useEffect(() => {
        fetchData()

        setInterval(async () => {
            await fetchData()
        }, 30000);

    }, []);


    const fetchData = async () => {
        await checkMaintenance()
    }

    const timeLeft = (date) => {
        const date1 = new Date(date);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60 ));

        if(diffHours < 12 ) {
            setMaintenanceNotice(24)
        } else if(diffHours > 12 &&  diffHours < 24 ) {
            setMaintenanceNotice(12)
        } else if(diffHours > 24 ) {
            setMaintenanceNotice(false)
            localStorage.removeItem('maintenanceAccept');
        }
    }

    const checkMaintenance = async () => {
        const data = await getMaintenanceMode();
        setMaintenance(data.data.is_activated)
        if(data.data.advance_notice) {
            timeLeft(data.data.advance_notice)
        } else {
            setMaintenanceNotice(false)
            localStorage.removeItem('maintenanceAccept');
        }
    }

    return <I18nextProvider i18n={i18next}>

        {/*permet de charger le background color depuis le localStorage // utile quand l'utilisateur reviens sur la page sans le relog*/}
        {IniBackgroundColor()}
        <Router>
            <Suspense fallback={<Loading/>}>
                <>
                    {(maintenanceNotice === 24 || maintenanceNotice === 12) &&
                    <MaintenanceNotice noticeTime={maintenanceNotice}/>
                    }
                    {maintenance && user && !user.roles.includes('ROLE_ADMIN') ?  (
                            <Maintenance maintenanceNotice={maintenanceNotice}/>
                        ) :
                        (
                            <>
                                <TopBar/>
                                <ToastContainer/>
                                <Switch>
                                    <ProtectedRoute exact path={DASHBOARD_ROUTE}>
                                        <DashBoardPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={PROFILE_ROUTE}>
                                        <ProfilePage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={INVITATION_ROUTE}>
                                        <InvitationPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={PROVIDER_LIST_ROUTE}>
                                        <ProviderListPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={TYPE_LIST}>
                                        <TypeListPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={EVENT_ROUTE}>
                                        <EventPreviewPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={SERVICE_ROUTE}>
                                        <ServicePreviewPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={BILLING_SUCCESS_ROUTE}>
                                        <BillingSuccessPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={BILLING_FAILURE_ROUTE}>
                                        <BillingFailurePage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={EVENT_LIST_PATH}>
                                        <EventListPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={PROVIDER_SERVICE_ROUTE}>
                                        <ProviderServiceList/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={PROVIDER_SERVICE_SETTINGS}>
                                        <ProviderServiceSetting/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={CALCULATOR_ROUTE}>
                                        <CalculatorPage/>
                                    </ProtectedRoute>
                                    {/* NEW ROUTES FOR CALCULATOR */}
                                    <ProtectedRoute exact path={NEW_CALCULATOR_ROUTE}>
                                        <CalculatorPage/>
                                    </ProtectedRoute>
                                    {/* END OF NEW ROUTES FOR CALCULATOR */}
                                    <ProtectedRoute exact path={INVOICES_ROUTE}>
                                        <InvoicesPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={SUBSCRIPTION_ROUTE}>
                                        <OffersPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={CATALOGUE_ROUTE}>
                                        <Catalogue/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={INGREDIENTS_ROUTE}>
                                        <Ingredients/>
                                    </ProtectedRoute>
                                    {/*                    <ProtectedRoute exact path={ADMINISTRATOR_ROUTE}>
                        <AdministratorPage/>
                    </ProtectedRoute>*/}
                                    <IsUserRedirect  exact path={REGISTER_ROUTE}>
                                        <RegisterPage/>
                                    </IsUserRedirect>
                                    <IsUserRedirect  exact path={LOGIN_ROUTE}>
                                        <LoginPage/>
                                    </IsUserRedirect>
                                    <ProtectedRoute exact path={ELEARNING_ROUTE}>
                                        <ModuleListPage/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={MODULE_GET_DETAIL}>
                                        <AdminQCM/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={DOC_GET_DETAIL_ADMIN}>
                                        <ListDocs/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={DOC_GET_DETAIL_USER}>
                                        <ListDocs/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={EVAL_QCM}>
                                        <EvalQCM/>
                                    </ProtectedRoute>
                                    <ProtectedRoute exact path={`${COMPARISON_ROUTE}/:event`}>
                                        <ScenarioComparison />
                                    </ProtectedRoute>
                                    <IsUserRedirect exact path={FORGOT_ROUTE}>
                                        <ForgotPassword/>
                                    </IsUserRedirect>
                                    <IsUserRedirect exact path={RESET_ROUTE}>
                                        <ResetPassword/>
                                    </IsUserRedirect>
                                    <Route exact path={LOGOUT_ROUTE}>
                                        <LogoutFunction/>
                                    </Route>
                                    <Route exact path={FAQ_ROUTE}>
                                        <FaqPage/>
                                    </Route>
                                    <Route exact path="/forgot">
                                        <ForgotPassword/>
                                    </Route>
                                    <Route exact path={CONTACT_ROUTE} component={ContactPage}/>
                                    <Route exact path={ACCEPT_INVITATION_ROUTE}>
                                        <AcceptInvitationPage/>
                                    </Route>
                                    <Route exact path="/"
                                        component={props => <HomePage {...props} openSlide={openSlide} toggleSlide={toggleSlide}/>}/>
                                    <Route path="*">
                                        <NotFoundPage/>
                                    </Route>
                                </Switch>
                            </>
                        )
                    }
                </>
            </Suspense>
        </Router>

    </I18nextProvider>
}

export default App;
