export const LOGIN_ROUTE = "/login"
export const LOGOUT_ROUTE = "/logout"
export const REGISTER_ROUTE = "/register"
export const CONTACT_ROUTE = "/contact"
export const FORGOT_ROUTE = "/forgot"
export const RESET_ROUTE = "/reset"

//Calculator
export const CALCULATOR_SETTINGS = "settings"
export const CALCULATOR_PHYSICALS = "physicals"
export const CALCULATOR_DIGITALS = "digitals"
export const CALCULATOR_SERVICES = "services"
export const CALCULATOR_ROUTE = "/calculator/:page"
export const CALCULATOR_SETTING_ROUTE = "/calculator/" + CALCULATOR_SETTINGS
export const CALCULATOR_PHYSICALS_ROUTE = "/calculator/" + CALCULATOR_PHYSICALS
export const CALCULATOR_DIGITALS_ROUTE = "/calculator/" + CALCULATOR_DIGITALS
export const CALCULATOR_SERVICES_ROUTE = "/calculator/" + CALCULATOR_SERVICES


export const NEW_CALCULATOR_ROUTE = "/:eventId/:scenarioId?/calculator/:page"
export const NEW_CALCULATOR_SETTING_ROUTE = "/:eventId/:scenarioId?/calculator/" + CALCULATOR_SETTINGS
export const NEW_CALCULATOR_PHYSICALS_ROUTE = "/:eventId/:scenarioId?/calculator/" + CALCULATOR_PHYSICALS
export const NEW_CALCULATOR_DIGITALS_ROUTE = "/:eventId/:scenarioId?/calculator/" + CALCULATOR_DIGITALS
export const NEW_CALCULATOR_SERVICES_ROUTE = "/:eventId/:scenarioId?/calculator/" + CALCULATOR_SERVICES

//Dashboard
export const DASHBOARD_ROUTE = "/dashboard/:page?"
export const DASHBOARD_PATH = "/dashboard"
export const DASHBOARD_EDIT = "edit"
export const DASHBOARD__DELETE = "delete"
export const DASHBOARD_DOWNLOAD = "download"

//Administrator
export const ADMINISTRATOR_ROUTE = "/administrator/:page?"
export const ADMINISTRATOR_PATH = "/administrator"
export const ADMINISTRATOR_EF = "ef"
export const ADMINISTRATOR_EF_COUNTRY = "ef-country"

//Event
export const EVENT_LIST_PATH = "/eventList"
export const EVENT_PATH = "/event"
export const EVENT_ROUTE = "/event/:event"

export const SERVICE_PATH = "/service"
export const SERVICE_ROUTE = "/service/:event"

export const PROFILE_ROUTE = "/profile"
export const SUBSCRIPTION_ROUTE = "/subscription"
export const ELEARNING_ROUTE = "/e-learning"
export const INVITATION_ROUTE = "/invitation"
export const PROVIDER_LIST_ROUTE = "/provider_list"
export const CATALOG_ROUTE = "/catalogue"
export const HOME_ROUTE = "/"
export const INVOICES_ROUTE = "/invoices"
export const OFFERS_ROUTE = "/offers"
export const BILLING_SUCCESS_ROUTE = "/billing_success"
export const BILLING_FAILURE_ROUTE = "/billing_cancel"
export const FAQ_ROUTE = "/faq"


export const MODULE_GET_DETAIL = "/admin/module/:module"
export const EVAL_QCM = "/module/:module"
export const DOC_GET_DETAIL_ADMIN = "/admin/doc/:module"
export const DOC_GET_DETAIL_USER = "/listdoc/:module"

export const COMPARISON_ROUTE = "/compare"

export const CATALOGUE_ROUTE = "/catalogue"
export const TYPE_LIST = "/types"

export const INGREDIENTS_ROUTE = "/ingredients"

export const PROVIDER_SERVICE_ROUTE = "/provider_service_list"
export const PROVIDER_SERVICE_SETTINGS = "/provider_service/settings"
export const ACCEPT_INVITATION_ROUTE = "/acceptInvitation"
