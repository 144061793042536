import {resolve} from "../../hook/resolver";
import axios from "axios";
import {
    LOGIN_API_PATH,
    REGISTER_API_PATH,
    FORGOT_API_PATH,
    IS_VERIFIED_USER,
    GET_MAINTENANCE,
    TOGGLE_MAINTENANCE,
    RESEND_ACTIVATION_TOKEN_EMAIL, CREATE_INGREDIENT, TRIGGER_MAINTENANCE_NOTICE
} from "../../constants/apiService";
import {toast} from "react-toastify";

export const loginCheck = async (credential) => {
    let data = {username: credential.email, password: credential.password}
    return await resolve(axios.post(LOGIN_API_PATH, data).then(r => r.data))
}

export const registerUser = async (user) => {
    return await resolve(axios.post(REGISTER_API_PATH, user).then(r => r.data))
}

export const getMaintenanceMode = async () => {
    return await resolve(axios.get(GET_MAINTENANCE).then(r => r.data))
}

export const toggleMaintenanceMode = async (token) => {
    return await resolve(axios.post(TOGGLE_MAINTENANCE, {}, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const triggerMaintenanceNotice = async (token) => {
    return await resolve(axios.post(TRIGGER_MAINTENANCE_NOTICE, {}, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const forgotPassword = async (credential) => {
    let data = {email: credential.email}
    return await resolve(axios.post(FORGOT_API_PATH, data).then(r => r.data))
}

export const resetPassword = async (credential) => {
    let data = {password: credential.password}
    return await resolve(axios.put(FORGOT_API_PATH + `/${credential.token}`, data).then(r => r.data))
}

export const verifyUser = async (registerToken) => {
    return await resolve(axios.post(IS_VERIFIED_USER, registerToken).then(r => r.data).then((r) => {
        r.success ? toast.success('Votre compte est activé', {toastId: 'accountActivate1'}) : toast.error('Token expiré ou invalide')
    }))
}

export const resendTokenEmail = async (userEmail) => {
    return await resolve(axios.post(RESEND_ACTIVATION_TOKEN_EMAIL, userEmail).then(r => r.data))
}
