import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {deleteType} from "../utils/api/PieceType";
import {toast} from "react-toastify";
import {TOAST_CONFIG} from "../constants/toast";
import {getComplementaryColor} from "../actions/backgroundColor";

export default function DeleteModal(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')

    useEffect(() => {


    }, [])



    const handleDeleteAction = async () => {
        props.deleteAction()
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <div className="font-bold relative p-6 flex-auto blancCasse">

                            {props.text}

                        </div>


                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.closeModal(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>
                            <button
                                className="btn-create font-bold text-sm px-2 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    handleDeleteAction();
                                    props.closeModal(false);

                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}
