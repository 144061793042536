import {useTranslation} from "react-i18next";
import logo from "../../../images/logo_blanc.png";
import co2LogoFR from "../../../images/SouthPole - CO2logic_logo_RGB.png";
import co2LogoEN from "../../../images/southpole---co2logic-logo-rgb-1.png";
import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {Modal} from "../../components/modal/modal"
import {
    ADMINISTRATOR_EF,
    ADMINISTRATOR_PATH,
    CALCULATOR_SETTING_ROUTE,
    SUBSCRIPTION_ROUTE,
    ELEARNING_ROUTE,
    CONTACT_ROUTE,
    LOGIN_ROUTE, LOGOUT_ROUTE,
    PROFILE_ROUTE,
    REGISTER_ROUTE,
    INVOICES_ROUTE,
    OFFERS_ROUTE,
    PROVIDER_LIST_ROUTE,
    CATALOG_ROUTE,
    EVENT_LIST_PATH, FAQ_ROUTE, PROVIDER_SERVICE_ROUTE, INVITATION_ROUTE
} from "../../constants/appRoute";

import {
    LOCAL_STORAGE_USER,
    LOGIN_SUCCESS,
// } from "../constants/securityConstant";
} from "../../constants/securityConstant";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faCircleQuestion, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {updateLanguage} from "../../actions/languageAction";
import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {getComplementaryColor} from "../../actions/backgroundColor";
import {NEW_APP_URL, WEBSITE_URL} from "../../constants/apiEndPoint";
import {authorization} from "../../actions/securityAction";
import frFlag from "../../../images/frFlag.png"
import ukFlag from "../../../images/ukFlag.png"
import {Tooltip} from "react-tooltip";
import { useHistory } from "react-router-dom";

const FAQRoute = 'https://climeet.events/faq-climeet'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const TopBar = () => {
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation('common');
    const co2Logo = t('common:administration.CO2logic') === "CO2logicFR" ? co2LogoFR : co2LogoEN;
    const [active, setActive] = useState(false);
    const {language} = useSelector(state => state.language)
    const history = useHistory()
    const {user} = useSelector(state => state.security)
    // const isAdmin = user?.roles.includes("ROLE_ADMIN")
    const customization = useSelector(state => state.customization)
    const handleLanguage = (lang) => {
        dispatch(updateLanguage(lang))
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        if (user && user.roles.includes('ROLE_ORGANIZER')){
            window.location.href = NEW_APP_URL
        }
    },[user])

    

    // useEffect pour remettre le nom dans redux à chaque refresh
    // useEffect(() => {
    //     const storedUserData = localStorage.getItem(LOCAL_STORAGE_USER);
    //     if (storedUserData) {
    //         const userData = JSON.parse(storedUserData);
    //         dispatch({ type: LOGIN_SUCCESS, payload: userData });
    //     }
    // }, [dispatch]);

    const [showBugModal, setShowBugModal] = useState(false)
    const [showQuestionModal, setShowQuestionModal] = useState(false)


    // const perm = () => {
    //     if (user.roles.length === 0) {
    //         return <Link to={{pathname: CONTACT_ROUTE, state: {unlocking: true}}}
    //                      className="block mt-4 lg:inline-block lg:mt-0 font-bold text-blue-unlock hover:text-travel-blue-bg mr-6">
    //             {t('topbar.unlock')}
    //         </Link>
    //     }
    //     return null
    // }

    const location = useLocation()
    const ignoreCustomization = ['/faq', '/register', "/login"]



    return (<>
        <Modal isModalOpen={showBugModal} setIsModalOpen={setShowBugModal}>
            <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={() => (setShowBugModal(false))}>
                <div className="relative w-auto my-6 mx-auto max-w-5xl" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                          
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.5)] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="flex justify-end pt-2 pl-2 pr-2 pb-0">
                            <span className="font-bold blancCasse cursor-pointer yellowHover mr-4 mt-2" onClick={() => (setShowBugModal(false))}><FontAwesomeIcon icon={faXmark} /></span>
                        </div>      
                        <div className="blancCasse relative pb-6 pl-6 pr-6 flex-auto">
                            <p className="mt-1 mb-3 leading-relaxed text-center">{t('bugReport.description.2')}</p>
                            <p className="my-3 leading-relaxed text-center">
                                {t('bugReport.description.3a')}
                                <a href="mailto:support@climeet.events" className="font-bold underline">support@climeet.events</a>
                                {t('bugReport.description.3b')}
                            </p>
                            <li className={"mb-2"}>{t('bugReport.todoList.1')}</li>
                            <li className={"mb-2"}>{t('bugReport.todoList.2')}</li>
                            <li className={"mb-2"}>{t('bugReport.todoList.3')}</li>
                            <li className={"mb-2"}>{t('bugReport.todoList.4')}</li>
                            <p className="my-4 leading-relaxed text-center">{t('bugReport.seeYouSoon')}</p>
                        </div>
                        {/*footer*/}
                        {/* <div
                            className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b px-16">
                            <button
                                className="btn-create font-bold text-sm px-2 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={()=>(setShowBugModal(false))}
                            >
                                {t("modal.finish.confirm")}
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
        </Modal>
        <Modal isModalOpen={showQuestionModal} setIsModalOpen={setShowQuestionModal}>
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={() => (setShowQuestionModal(false))}>
                    <div className="relative w-auto my-6 mx-auto max-w-5xl" onClick={(e) => {e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.5)] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="flex justify-end pt-2 pl-2 pr-2 pb-0">
                            <span className="font-bold blancCasse cursor-pointer yellowHover mr-4 mt-2" onClick={() => (setShowQuestionModal(false))}><FontAwesomeIcon icon={faXmark} /></span>
                        </div>  
                        <div className="blancCasse relative pb-6 pl-6 pr-6  flex-auto">
                            <p className="mt-1 mb-3 leading-relaxed text-center">{t('questionModal.1')}</p>
                            <p className="my-3 leading-relaxed text-center">
                                {t('questionModal.2a')}
                                <a href={FAQRoute} className="font-bold underline" onClick={() => setShowQuestionModal(false)} target="_blank" rel="noopener noreferrer">
                                    FAQ
                                </a>
                                {t('questionModal.2b')}

                            </p>
                            <p className="my-3 leading-relaxed text-center">
                                {t('questionModal.3')}
                                <a href="mailto:support@climeet.events" className="font-bold underline">support@climeet.events</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
        </Modal>
        

        <nav
            style={{backgroundColor: !ignoreCustomization.includes(location.pathname) ? customization.backgroundColor : "#10384E", zIndex : "48"}}
            className="topbar-nav sticky top-0 flex items-center justify-between flex-wrap bg-green-light z-40 py-6 px-8">
            {/* <Link to={"/"} className="block mt-4 lg:inline-block lg:mt-0 text-black mr-6"> */}
            <div className="block mt-4 lg:inline-block lg:mt-0 text-black mr-6">
                <div className="flex items-center flex-shrink-0 mr-6">
                    {/*<img style={{height: "45px"}} className="topbar-logo mr-4" src={co2Logo} alt="src logic"/>*/}
                    {/*<img style={{height: "87px", width: "87px"}} className="topbar-logo" src={logo} alt="green evenment"/>*/}
                    <img className="logo-topbar" src={logo} alt="Climeet"/>
                </div>
            </div>
            {/* </Link> */}
            <div className="block lg:hidden">
                <button onClick={() => setActive(a => !a)}
                        className="flex items-center px-3 py-2 border border-darker-green text-darker-green rounded text-teal-200 hover:bg-darker-green hover:blancCasse hover:border-white">
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div>
            <div

                className={`w-full block ${!active && "hidden"} lg:flex lg:items-center lg:w-auto`}>
                <div className="text-base lg:flex items-center">
                    {/*<a href={WEBSITE_URL}*/}
                    {/*   className="block mt-4 lg:inline-block lg:mt-0 blancCasse  mr-6">*/}
                    {/*    {t('topbar.home')}*/}
                    {/*</a>*/}
                    {
                        user &&
                        <>
                            {(user.roles.includes('ROLE_ORGANIZER') ||
                                    user.roles.includes('ROLE_PROJECT_MANAGER') ||
                                    user.roles.includes('ROLE_COMPANY') ||
                                    user.roles.includes("ROLE_COMPANY_VIEW")) &&
                                <Link to={EVENT_LIST_PATH}
                                      className="block lg:inline-block lg:mt-0 blancCasse  yellowHover mr-6">
                                    {t('topbar.events')}
                                </Link>
                            }
                            {/*{!user.roles.includes('ROLE_PROJECT_MANAGER') &&
                            !user.roles.includes('ROLE_STANDIST') &&
                            <Link to={SUBSCRIPTION_ROUTE}
                                      className="block mr-6 lg:inline-block lg:mt-0 blancCasse  ">
                                    {t('topbar.subscription')}
                                </Link>
                            }*/}
                            {authorization(user,'ROLE_ORGANIZER', 'ROLE_COMPANY') &&
                                <Link to={INVITATION_ROUTE}
                                      className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                    {t('topbar.team')}
                                </Link>}
                            {!user.roles.includes("ROLE_COMPANY_VIEW") && <Link to={ELEARNING_ROUTE}
                                  className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                {t('topbar.e-learning')}
                            </Link>}

                            {authorization(user,'ROLE_ORGANIZER', 'ROLE_COMPANY') &&
                               <Link to={PROVIDER_LIST_ROUTE}
                                     className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                   {t('topbar.suppliers')}
                               </Link> 
                            }
                            {authorization(user,'ROLE_CATERER', 'ROLE_STANDIST') &&
                                <Link to={CATALOG_ROUTE}
                                      className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                    {t('topbar.catalog')}
                                </Link>}

                            {authorization(user,'ROLE_CATERER') &&
                                <Link to={PROVIDER_SERVICE_ROUTE}
                                      className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                    {t('provider.title')}
                                </Link>
                            }
                            {authorization(user,'ROLE_STANDIST') &&
                                <Link to={PROVIDER_SERVICE_ROUTE}
                                      className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                    {t('provider.title')}
                                </Link>
                            }


                    <Menu as="div" className="relative inline-block text-left pt-0.5">
                        <div>
                            {user &&
                                <Menu.Button>
                                <span className={'block lg:inline-block lg:mt-0 blancCasse yellowHover'} style={{fontSize: "22px"}}>{user.username.length < 24 ? user.username : user.username.substring(0, 23) + '...'}</span>
                                </Menu.Button>
                            }
                        </div>

                        <Transition
                            style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                as={'item'}
                                className="menuHeader text-center py-1 px-5 origin-top-right absolute right-0 mt-2 w-80 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                    <Link to={PROFILE_ROUTE}
                                        className="block mr-6 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                        {t('topbar.profile')}
                                    </Link> 
                                    
                                    </Menu.Item>
                                </div>
                                <div className="py-1">
                                    <Menu.Item as={'sous-item'}>
                                        {user &&
                                            <Link to={LOGOUT_ROUTE}
                                                    className="block pt-2 pb-2 lg:inline-block lg:mt-0 blancCasse yellowHover">
                                                {t('common:topbar.singout')}
                                            </Link>
                                        }
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    </>}
                    {user && 
                    <div className={"flex items-center pt-0.5"}>
                        <Tooltip id={"topbar_tooltip"} multiline={false} className="tooltip max-w-xs rounded" textColor={"#fdc96e"}/>
                        <span>
                            <FontAwesomeIcon onClick={() => setShowBugModal(true)} className={'fa-1.5x blancCasse ml-5 cursor-pointer yellowHover' } icon={faBug} data-tooltip-id={"topbar_tooltip"} data-tooltip-content={t("bugReport.tooltip")}/>
                        </span>
                        <span>
                            <FontAwesomeIcon onClick={() => setShowQuestionModal(true)} className={'fa-1.5x blancCasse ml-5 cursor-pointer yellowHover'} icon={faCircleQuestion} data-tooltip-id={"topbar_tooltip"} data-tooltip-content={t("questionModal.tooltip")}/>
                        </span>
                    </div>
                    }
                    {!user && <div className="flex items-center">

                        <Link to={LOGIN_ROUTE}
                              className="block mt-4 lg:inline-block lg:mt-0 blancCasse text-black yellowHover mr-6">
                            {t('topbar.login')}
                        </Link>
                        <Link to={REGISTER_ROUTE}
                              className="block mt-4 mr-6 lg:inline-block btn-create lg:mt-0 font-bold border border-green-500 bg-green-600 rounded-md p-2 yellowHover">
                            {t('topbar.register')}
                        </Link>
                    </div>}
                    <button className="flex items-center ml-5 pt-0.5"
                            onClick={() => handleLanguage(language === "fr" ? 'en' : "fr")}>
                        <span
                            className="blancCasse font-bold yellowHover"
                        >{language === "fr" ? "FR" : "EN"} </span>
                        <img className={"h-4 ml-1"} src={language === "fr" ? frFlag : ukFlag}/>
                        
                    </button>
                </div>
            </div>
        </nav>
        </>
    )
}
