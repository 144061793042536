import React, { useEffect, useRef } from 'react';

// Modal qui se ferme lorsqu'on clique en dehors, ne passer que l'état d'affichage et son setter en argument, children est le contenu et doit être entre les balises de la modale
export const Modal = ({ isModalOpen, setIsModalOpen, children }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setIsModalOpen]);

    if (!isModalOpen) return null;

    return (
        <div className="modal-container">
            <div className="modal-content" ref={modalRef}>
                {children}
            </div>
        </div>
    );
};