import {resolve} from "../../hook/resolver";
import axios from "axios";
import {
    PIECE_LIST,
    PIECE_LIST_UPDATE,
    PIECE_LIST_CREATE
} from "../../constants/apiService";
import {toast} from "react-toastify";

export const getTypeList = async (token, page, data) => {
    return await resolve(axios.post(PIECE_LIST + '?page='+page, data,{
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const updateType = async (token, type) => {
    return await resolve(axios.put(PIECE_LIST_UPDATE, type,{
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const createType = async (token, type) => {
    return await resolve(axios.post(PIECE_LIST_CREATE, type, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const deleteType = async (token, id) => {
    return await resolve(axios.delete(PIECE_LIST +'/'+id, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

