import {resolve} from "../../hook/resolver";
import axios from "axios";
import {
    CHECK_IF_SUB,
    DELETE_EVENT,
    DUPLICATE_EVENT,
    GET_EVENT_LIST, GET_EVENT_OWNER,
    GET_GENERAL_PARAMS_USER,
    GET_PRESTASTION_LIST, MERGE_EVENTS, SET_EVENT_OWNER,
    SET_GENERAL_PARAMS
} from "../../constants/apiService";

export const deleteEvent = async ( token, id) => {
    return await resolve(axios.delete(DELETE_EVENT + `/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
    }))
}

export const duplicateEvent = async ( token, id, body) => {
    return await resolve(axios.post(DUPLICATE_EVENT + `/${id}`, body, {
        headers: {Authorization: `Bearer ${token}`}
    }))
}

export const mergeEvents = async (token, body) => {
    return await resolve(axios.post(MERGE_EVENTS, body, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const getGeneralParamsForUser = async ( token) => {
    return await resolve(axios.get(GET_GENERAL_PARAMS_USER, {
        headers: {Authorization: `Bearer ${token}`}
    }))
}

export const sendDataGeneralParam = async ( token, body, id) => {
    return await resolve(axios.post(SET_GENERAL_PARAMS + `/${id}`, body, {
        headers: {Authorization: `Bearer ${token}`}
    }))
}
export const getEvents = async (token, order = {}, page = 1, limit = 10) => {
    let paramsUrl = `?page=${page}&limit=${limit}`

    if (Object.keys(order).length > 0) {
        paramsUrl += '&orderKeys=' + Object.keys(order) + '&orderType=' + Object.values(order);
    }

    return await resolve(axios.get(GET_EVENT_LIST + paramsUrl, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const setEventOwner = async (token, owner_id, id) => {

    return await resolve(axios.post(SET_EVENT_OWNER+ `/${id}`, {"owner_id":owner_id}, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const getEventOwner = async (token, id) => {
    return await resolve(axios.get(GET_EVENT_OWNER+ `/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

