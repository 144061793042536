import {CLEAR_ERROR_MESSAGE, LOGIN_FAILED, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_FAILED, LOGOUT_SUCCESS, FORGOT_SENT} from "../constants/securityConstant";

export const securityReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_REQUEST :
            return {...state, loading: true}
        case LOGIN_SUCCESS:
            return {loading: false, user : action.payload}
        case FORGOT_SENT:
            return {loading: false}
        case LOGIN_FAILED:
            return {loading: false, error : action.payload}
        case CLEAR_ERROR_MESSAGE :
            return {loading: false, error : null}
        case LOGOUT_SUCCESS:
            return {}
        case LOGOUT_FAILED:
            return {loading: false, error : action.payload}
        default:
            return state
    }
}