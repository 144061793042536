import {resolve} from "../../hook/resolver";
import axios from "axios";
import {
    GET_MENU,
    GET_PIECE,
    CREATE_PIECE,
    UPDATE_PIECE,
    DELETE_PIECE,
    GET_ALLINGREDIENT,
    GET_ALLFURNITURE,
    GET_SIMPLE_PIECES,
    EXPORT_TO_CSV, DELETE_CSV_FILE

} from "../../constants/apiService";

export const getMenu = async (token, data, page) => {
    return await resolve(axios.post(GET_MENU+ '?page='+ page, data,{
        headers: {Authorization: `Bearer ${token}`},
    })).then(r => r.data)
}
export const getPiece = async (token, data, page) => {
    return await resolve(axios.post(GET_PIECE+ '?page='+ page, data,{
        headers: {Authorization: `Bearer ${token}`},
    })).then(r => r.data)
}

export const exportTypeToCsv = async (token, type) => {
    try {
        const response = await axios.post(EXPORT_TO_CSV, {'toExport': type}, {
            headers: { Authorization: `Bearer ${token}` },
        });
        // Vérifie si la réponse contient un champ "error"
        if (response.data && !response.data.error) {
            return response; // Retourne l'objet contenant l'erreur
        }
        return response; // Retourne l'objet de données en cas de succès
    } catch (error) {
        console.error("Error exporting type to CSV:", error);
        // Gérer ou relancer l'erreur selon les besoins
        // Peut-être retourner un objet d'erreur personnalisé pour une cohérence dans le gestionnaire d'erreur du front-end
        return { error: "Error while exporting" };
    }
}

export const deleteFile = async (token, filename) => {
    return await resolve(axios.post(DELETE_CSV_FILE, {'filename' : filename},{
        headers: {Authorization: `Bearer ${token}`},
    })).then(r => r.data)
}

export const getSimplePieces = async (token) => {
    return await resolve(axios.get(GET_SIMPLE_PIECES,{
        headers: {Authorization: `Bearer ${token}`},
    })).then(r => r.data)
}

export const createPiece = async (token, data) => {
    return await resolve(axios.post(CREATE_PIECE, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const updatePiece = async (token, data) => {
    return await resolve(axios.put(UPDATE_PIECE, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const deletePiece = async (token, data) => {
    try {
        const response = await axios.delete(DELETE_PIECE + '/' + data.id, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // Si Axios a reçu une réponse d'erreur du serveur, relancez avec ce détail
            throw {
                status: error.response.status,
                message: error.response.data.message
            };
        } else {
            // Si l'erreur est due à autre chose (réseau, etc.), relancez un message générique
            throw {
                status: 500,
                message: "Network Error"
            };
        }
    }
}

export const getAllIngredients = async (token, page, onlyMyIngredients, dataObj) => {
    return await resolve(axios.post(GET_ALLINGREDIENT + '?page='+page + '&onlyMyIngredients='+onlyMyIngredients, dataObj, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const getAllFurniture = async (token, page, onlyMyIngredients, dataObj) => {
    return await resolve(axios.post(GET_ALLFURNITURE + '?page='+page + '&onlyMyIngredients='+onlyMyIngredients, dataObj, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}
