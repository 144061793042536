import React, {useEffect, Fragment} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Loading from "../components/loading/Loading";
import {useHistory, useLocation} from "react-router-dom";
import {LOGIN_ROUTE} from "../constants/appRoute";
import {toast} from "react-toastify";
import {resolve} from "../hook/resolver";
import axios from "axios";
import {ACCEPT_INVITATION_BY_CODE} from "../constants/apiService";


export default function AcceptInvitationPage(props) {

    const customization = useSelector(state => state.customization)
    const {language} = useSelector(state => state.language)
    const [t] = useTranslation("common");

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const code = searchParams.get('code') // get the code
    const history = useHistory()

    const checkInviteCode = async (code) => {
        return await resolve(axios.post(ACCEPT_INVITATION_BY_CODE,{'code':code})).then(r => r.data);
    }

    useEffect(async () => {

        if(code){
            let result = await checkInviteCode(code);
            if(result && result.data) {
                if (result.data.success === true) {
                    toast.success("Invitation acceptée");
                } else {
                    toast.error("Invitation introuvable");
                }
            } else {
                toast.error("Une erreur est survenue");
            }
            history.push(LOGIN_ROUTE);

        } else {
            history.push(LOGIN_ROUTE);
        }



    }, [])

    return (
        <>
            <Loading/>
        </>
    )
}
