import {
    BACKGROUND_BOX,
    BACKGROUND_COLOR, TITLE_COLOR,
} from "../constants/calculatorConstant"

export const customizationReducer = (state = {}, action) => {


    switch (action.type) {
        case BACKGROUND_COLOR :
            return {backgroundColor : action.payload}
        case BACKGROUND_BOX :
            return {backgroundBox: action.payload}
       case TITLE_COLOR :
            return {titleColor: action.payload}
        default:
            return state
    }
}