import {
    LOCAL_STORAGE_USER,
    LOGIN_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    FORGOT_SENT,
    LOGOUT_SUCCESS
} from "../constants/securityConstant";
import {loginCheck, registerUser, logoutUser, forgotPassword, resetPassword} from "../utils/api/security";
import {loggedUser} from "../hook/authDecoder";
import {Redirect} from "react-router-dom";
import {BACKGROUND_COLOR} from "../constants/calculatorConstant";
import {LOGIN_ROUTE} from "../constants/appRoute";
import {toast} from "react-toastify";
import {getUser} from "../utils/api/profile";

export const loginAction = (data) => async (dispatch) => {
    dispatch({type: LOGIN_REQUEST, payload: data})
    const r = await loginCheck(data)
    if (r.error) {

        dispatch({type: LOGIN_FAILED, payload: r.error})
    } else {
        
        // localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(r.data))
        // // Ajout de getUser pour ajouter le prénom au redux
        // const userData = loggedUser()
        // const userDetails = await getUser(userData.id, r.data.token)
        
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify({token: r.data.token}));
        dispatch({type: LOGIN_SUCCESS, payload: {token: r.data.token, ...loggedUser()}})
    }
}

export const registerAction = (user) => async (dispatch) => {
    dispatch({type: LOGIN_REQUEST, payload: user})
    const r = await registerUser(user)
    if (r.error) {

        let splitError = r.error["hydra:description"].split('\n');

        splitError.forEach((e) => {
            dispatch({type: LOGIN_FAILED, payload: e})
        })
    } else {
        window.location.replace(LOGIN_ROUTE);
        sessionStorage.setItem("activationmsg", "1");
    }
}

export const logoutAction = (user) => async (dispatch) => {
    //localStorage.clear()
    for (var key in localStorage) {
        if (key != "ELEARNING_PROGRESS") {
            localStorage.removeItem(key)
        }
    }
    dispatch({type: LOGOUT_SUCCESS, payload: user});
}

export const forgotAction = (data) => async (dispatch) => {
    // dispatch({type: LOGIN_REQUEST, payload: data})
    // const r = await forgotPassword(data)
    // if (r.error) {
    //     dispatch({type: LOGIN_FAILED, payload: r.error.message})
    // } else {
    //     dispatch({type: FORGOT_SENT, payload: {}})
    // }
}

export const resetAction = (data) => async (dispatch) => {
    // dispatch({type: LOGIN_REQUEST, payload: data})
    // const r = await resetPassword(data)
    // if (r.error) {
    //     dispatch({type: LOGIN_FAILED, payload: r.error.message})
    // } else {
    //     dispatch({type: FORGOT_SENT, payload: {}})
    // }
}


export const authorization = (user, ...rolesAuthorizeToDoThisAction) => {

    for (let role of rolesAuthorizeToDoThisAction) {
        if (user.roles.includes(role)) {
            return true;
        }

    }
    return false;
}
