import {Disclosure} from '@headlessui/react'
import {ChevronUpIcon} from '@heroicons/react/solid'
import {Tooltip} from 'react-tooltip'
import {faCircleInfo, faMagnifyingGlassMinus, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {getComplementaryColor} from "../../actions/backgroundColor";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


export default function Desclosure({
                                       label,
                                       legend = null,
                                       children,
                                       size = "md",
                                       tooltip = false,
                                       MoreclassName = "",
                                       nbOfLines = false,
                                       tooltipId = null,
                                        hasAdvice=false,
                                        advice = null,
                                       ...props
                                   }) {
    const customization = useSelector(state => state.customization)
    const [adviceOpen, setAdviceOpen] = useState(false);
    const {t} = useTranslation('common');

    const handleAdviceOpen = () => {
        setAdviceOpen(!adviceOpen);
    }

    return (
        <div className={"px-4 pt-6 w-full ".concat(MoreclassName)}>
            <div className={`w-full max-w-${size} p-2 border-2 rounded`}>
                <Disclosure>
                    {({open}) => (
                        <>
                            <Disclosure.Button
                                style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                className={`flex justify-between w-full px-4 py-2 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-${label.color}-500 focus-visible:ring-opacity-75`}>
                                <span>
                                    {label.icon}
                                    {label.text}
                                    {tooltip && <span className={'ml-3'}>
                                        <FontAwesomeIcon className="tooltip-icon"
                                                         data-tooltip-id={tooltipId ?? label.text} data-tooltip-content={tooltip ? tooltip : null}
                                                         icon={faCircleInfo} />
                                    </span>}
                                    {hasAdvice && <span className={'ml-8'}>
                                        <span className="text-blancCasse hover:text-climeetYellow transition-all text-center border-b border-transparent hover:border-climeetYellow"
                                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAdviceOpen(); }}>
                                            {t('adviceModal.button')}
                                            <FontAwesomeIcon className="ml-2 text-climeetYellow" icon={faMagnifyingGlassMinus}/>
                                        </span>

                                    </span> }

                                </span>
                                {
                                    tooltip &&
                                    <Tooltip id={tooltipId ?? label.text} multiline={true} className="tooltip max-w-xs rounded" textColor={"#fdc96e"}/>
                                }
                                <div className="flex"> 
                                    { typeof nbOfLines === 'number' && !isNaN(nbOfLines) && nbOfLines !== 0 &&
                                        <span className="mr-5 opacity-60">{nbOfLines} {nbOfLines > 1 ? t('nbOfLines.plural') : t('nbOfLines.singular')}</span>                                 
                                    }
                                    <ChevronUpIcon
                                        className={`${
                                            !open ? 'transform rotate-180' : ''
                                        } w-5 h-5`}
                                    />
                                </div>
                            </Disclosure.Button>
                            {legend && <p className="blancCasse text-xs pl-5">{legend} </p>}
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                {children}
                            </Disclosure.Panel>
                            {hasAdvice && adviceOpen &&
                                <>
                                    <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
                                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={() => handleAdviceOpen()}>
                                        <div className="relative w-auto my-6 mx-auto max-w-5xl" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                            {/*content*/}
                                            <div
                                                style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div
                                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <div>
                                                        <h3 className="text-3xl font-semibold text-center blancCasse">{t('adviceModal.title')}</h3>
                                                        <p className={'text-xs blancCasse'}>{t('adviceModal.maj')}</p>
                                                    </div>
                                                    <button
                                                        className="p-1 ml-auto bg-transparent border-0 text-white focus:outline-none float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => handleAdviceOpen()}
                                                    >
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </button>
                                                </div>
                                                {/*body*/}
                                                <div className="relative p-6 flex-auto">
                                                    {advice}
                                                </div>
                                                {/*footer*/}
                                                <div
                                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                        
                                                    <button
                                                        className="text-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => handleAdviceOpen()}
                                                    >
                                                    {t('adviceModal.close')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </>
                            }
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    )
}
