import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getComplementaryColor} from "../actions/backgroundColor";
import {getTypeList} from "../utils/api/PieceType";
import {faExclamationCircle, faFileInvoice, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTruck} from "@fortawesome/free-solid-svg-icons/faTruck";
import {Tooltip} from 'react-tooltip'
import {CATALOG_ROUTE, PROVIDER_SERVICE_ROUTE, SERVICE_PATH} from "../constants/appRoute";
import {useHistory} from "react-router-dom";
import {authorization} from "../actions/securityAction";


export default function ChoseDirectionModal(props) {

    const {t} = useTranslation('common');
    const history = useHistory()
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)

    const redirectTo = (path) => {
        localStorage.setItem('DIRECTION_CHOSE', path)
        if(path === '/catalogue') {
            props.closeModal(false)
        }
        history.push(path)
    }

    useEffect(async () => {
    }, [])

    return (<>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div 
                className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none w-full focus:outline-none" 
            >
                <div className="relative my-6 w-2/6">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10">
                        <h1 className="text-2xl text-center text-jaune blancCasse my-6">{t("choseDirection.title")}</h1>

                        {/*body*/}
                        {/*footer*/}
                        <div className="relative p-6 justify-center flex text-jaune">
                            <Tooltip id="my-tooltip" multiline={true} className="tooltip rounded max-w-12" textColor={"#fdc96e"}>
                            {authorization(user, 'ROLE_CATERER') ? 
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{t("choseDirection.tooltip_1a.line1")}</span>
                                    <span>{t("choseDirection.tooltip_1a.line2")}</span>
                                </div>
                            :
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{t("choseDirection.tooltip_1.line1")}</span>
                                    <span>{t("choseDirection.tooltip_1.line2")}</span>
                                </div>
                            }
                            </Tooltip>
                            <div className='flex w-full flex-col justify-between items-center text-center' data-tooltip-id="my-tooltip">
                                <span className='mb-7' >
                                    <FontAwesomeIcon size="3x" className="text-center" icon={faFileInvoice}/>
                                </span>
                                <button className={"btn-create font-bold text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"} onClick={() => {redirectTo(CATALOG_ROUTE)}}>{t("choseDirection.project_1")} <br/>{t("choseDirection.project_2")}</button>
                            </div>
                            <Tooltip id="my-tooltip2" multiline={true} className="tooltip rounded max-w-12" textColor={"#fdc96e"}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{t("choseDirection.tooltip_2.line1")}</span>
                                    <span>{t("choseDirection.tooltip_2.line2")}</span>
                                </div>
                            </Tooltip>
                            <div className='flex w-full flex-col justify-between items-center text-center'  data-tooltip-id="my-tooltip2">
                                <span>
                                    <FontAwesomeIcon size="3x" className="text-center" icon={faTruck}/>
                                </span>
                                <button className={"btn-create font-bold text-sm text-center px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"} onClick={() => {redirectTo(PROVIDER_SERVICE_ROUTE)}}>{t("choseDirection.delivery_1")} <br/>{t("choseDirection.delivery_2")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
