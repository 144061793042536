
import React, {useState, useRef, useEffect} from 'react'

export const CompletInput = ({ error = false, size = "xs",errorHelper, bg_sup="", ...props }) => {
    return <div className="flex justify-center">
        <input {...props} className={`appearance-none md:max-w-${size} block w-full text-gray-700 border ${error && "border-red-500"} rounded px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none ` + " " + bg_sup} />
        {error && <p className="text-red-500 text-xs italic">{errorHelper}</p>}
    </div>
}

export const ChoiceInput = ({ children, width=false, bg_sup="", textColorClass = "", ...props }) => {

    return (
        <div className={`inline-block relative ${width && "w-64"}`}>
            <select {...props} className={" block appearance-none w-full  border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline".concat(" ", bg_sup, " ", textColorClass)}>
                {children}
            </select>
        </div>
    )
}

// dans les props : options correspond à la liste du menu déroulant
export const SearchableInput = ({ options, onSelectChange, initialValue, width=false, bg_sup="", textColorClass="" , styleClass=""}) => {
    const [displayValue, setDisplayValue] = useState(initialValue ?? '')
    const [searchTerm, setSearchTerm] = useState("");

    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputFocused, setInputFocused] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!searchTerm) {
            setFilteredOptions(options);
            return;
        }
        const filtered = options.filter(option =>
            option.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOptions(filtered);
    }, [searchTerm, options]);

    const handleOptionClick = (option) => {
        setDisplayValue(option.name);
        setSearchTerm("");
        setInputFocused(false);
        onSelectChange(option.id);
    };

    const handleClickOutside = event => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setInputFocused(false);
        }
    };

    useEffect(() => {
        setDisplayValue(initialValue)
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [initialValue]);

    return (
        <div ref={containerRef} className={`inline-block relative ${width && "w-64"}`}>
            <input
                value={searchTerm}
                className={`rounded h-100 ${styleClass}`}
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={() => {
                    setInputFocused(true)
                    setSearchTerm('')
                    setDisplayValue(null)
                }}
                placeholder={displayValue || "Rechercher"}
                style={{paddingLeft:'10px', height:'30px'}}
            />
            {inputFocused && (filteredOptions.length > 0) &&  (
                <div className={`absolute z-50 block w-full bg-inputBackgroundChrome ${textColorClass} border border-gray-400 blancCasse hover:border-gray-500 shadow leading-tight focus:outline-none focus:shadow-outline`}
                style={{maxHeight: '200px', overflowY: 'auto', width: '200px'}}>
                    {filteredOptions?.map(option => (
                        <div 
                            key={option.id}
                            onClick={() => handleOptionClick(option)}
                            className={`cursor-pointer hover:bg-blue-600 p-0.5 text-base`}
                            style={{minWidth: '150px'}}
                        >
                            {option.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
