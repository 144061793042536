import {resolve} from "../../hook/resolver";
import axios from "axios";
import {GET_ALLINGREDIENT, CREATE_INGREDIENT, UPDATE_INGREDIENT, DELETE_INGREDIENT} from "../../constants/apiService";

export const getAllIngredients = async (token) => {
    return await resolve(axios.get(GET_ALLINGREDIENT, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const createIngredient = async (token, data) => {
    return await resolve(axios.post(CREATE_INGREDIENT, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const updateIngredient = async (token, data) => {
    return await resolve(axios.put(UPDATE_INGREDIENT, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const deleteIngredient = async (token, data) => {
    return await resolve(axios.delete(DELETE_INGREDIENT + "/" + data.id, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}