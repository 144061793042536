import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getComplementaryColor} from "../actions/backgroundColor";
import {getTypeList} from "../utils/api/PieceType";


export default function ConfirmModal(props) {

    const {t} = useTranslation('common');
    const dispatch = useDispatch()
    const [data, setData] = useState(false);
    const [name, setName] = useState('');

    const customization = useSelector(state => state.customization)

    const handleFinishAction = () => {
        props.finishAction(data)
        props.reloadAction(new Date());
    }

    useEffect(async () => {
        setData(props.selectedObject)
        if(props.isCreate) {
            let obj =  {[props.inputName.toLowerCase()] : ''}
            setName(obj)
        } else {
            let obj =  {[props.inputName.toLowerCase()] : props.selectedObject.name}
            setName(obj);
        }
    }, [])

    const handleTitle = () => {

        return !props.selectedObject.id ? t('catalogue.bloc.typology.add') : t('catalogue.bloc.typology.update');

    }


    const generateLabelButtonSave = () => {

        if (props.selectedObject.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');

        }

    };

    return (<>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.closeModal(false)}
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <h1 className="text-2xl text-center blancCasse my-6">{handleTitle()}</h1>

                        {/*body*/}
                        {/*footer*/}
                        <div className="relative p-6 block justify-center flex">

                            <div>
                                <div className={'flex'}>
                                    <label className={'inline-block text-right w-1/2 mr-6 mt-2'}>{props.inputName}</label>
                                    <input type={'text'} className={'mr-2'} style={{width: "200px", color: "black"}}
                                           defaultValue={name[props.inputName.toLowerCase()]}
                                           onChange={(e) => setData({...data, ...{name: e.target.value}})}
                                    />
                                </div>

                            </div>


                        </div>
                        <div
                            className="flex items-center justify-end p-6 border-solid border-slate-200 rounded-b px-16">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.closeModal(false)}
                            >
                                {t("modal.finish.cancel")}
                            </button>
                            <button
                                className="text-lg bg-blue-600 ml-5 border border-blue-500 font-bold  px-8 flex justify-center btn-create"
                                type="button"
                                onClick={() => {handleFinishAction(); props.closeModal(false)} }
                            >
                                {generateLabelButtonSave()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
