import React from "react";

export const GenerateOptions = (arr, id, premium = false, force_premium = false) => {
    return (
        Object.entries(arr).map(
            (value, k) => (
                <option
                    value={id[value[0]]}
                    key={k}

                >
                    {
                        value[1]
                    }
                </option>
            )
        )
    )
}