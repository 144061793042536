import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from "./js/App";
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import './fonts/MADE TOMMY Black.otf';
import './fonts/MADE TOMMY Light.otf';
import './fonts/MADE TOMMY Bold.otf';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
        <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap" rel="stylesheet"/>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
