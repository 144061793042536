import {useDispatch, useSelector, useStore} from "react-redux";
import {BACKGROUND_COLOR, BACKGROUND_BOX, TITLE_COLOR} from "../constants/calculatorConstant";

export const listBackgroundColor = () => {
    return [
        {name: 'Default', 'codeHexa': '#10384E', 'boxColor': '#193446', 'titleColor': '#A5BCC4'},
        {name: 'Black', 'codeHexa': '#000000', 'boxColor': '#1F2021', 'titleColor': '#7D5454'},
        {name: 'Charcoal', 'codeHexa': '#36454F', 'boxColor': '#3E505C', 'titleColor': '#95BFDB'},
        {name: 'Jet Black', 'codeHexa': '#343434', 'boxColor': '#5C5C5C', 'titleColor': '#DBDBDB'},
        {name: 'Licorice', 'codeHexa': '#1B1212', 'boxColor': '#5C3E3E', 'titleColor': '#9C5959'},
        {name: 'Onyx', 'codeHexa': '#353935', 'boxColor': '#555C55', 'titleColor': '#CCDBCC'},
        {name: 'Dark Green', 'codeHexa': '#023020', 'boxColor': '#057A51', 'titleColor': '#f2e6ce'}
    ];
}


export const getComplementaryColor = (type, codeHexa) => {

    let ret;

    if (!['boxColor', 'titleColor'].includes(type)) {
        return codeHexa;
    }

    const color = listBackgroundColor();

    color.forEach((item) => {

        if (item.codeHexa === codeHexa) {

            //console.log(item, item.boxColor);
            ret = item[type];
        }

    })


    return ret ||color[0][type];

}


export const IniBackgroundColor = () => {

    const dispatch = useDispatch();
    let backgroundColor = localStorage.getItem('BACKGROUND_COLOR');
    if (!backgroundColor) {
        dispatch({type: BACKGROUND_COLOR, payload: '#10384e'})
    } else {
        dispatch({type: BACKGROUND_COLOR, payload: backgroundColor})
    }
}