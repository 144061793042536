import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {getComplementaryColor, IniBackgroundColor} from "../../actions/backgroundColor";
import {ArrowTitle} from "../../components/shared/arrowTitle";
import {
    createPiece,
    deletePiece,
    getAllFurniture,
    getAllIngredients,
    getPiece,
    getMenu,
    updatePiece, exportToCsv, exportTypeToCsv, deleteFile
} from "../../utils/api/catalogue";
import {
    faChevronDown,
    faEdit,
    faTrash,
    faPen,
    faSearch,
    faCopy,
    faXmark,
    faFileExport, faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {deleteEvent} from "../../utils/api/event";
import {ChoiceInput, SearchableInput} from "../../components/shared/input/Input";
import {TYPE_LIST} from "../../constants/appRoute";
import {INGREDIENTS_ROUTE} from "../../constants/appRoute";
import {GenerateOptions} from "../../hook/translation";
import {createType, deleteType, getTypeList, updateType} from "../../utils/api/PieceType";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {createIngredient, deleteIngredient, updateIngredient} from "../../utils/api/ingredients";
import {TOAST_CONFIG} from "../../constants/toast";
import designInstallation from "../../../images/Design _ Installation@3x.png";
import Disclosure from "../../components/disclosure/index"
import ConfirmModal from "../../modal/confirmModal";
import DeleteModal from "../../modal/deleteModal";
import Pagination from "../../components/pagination/pagination";
import {authorization} from "../../actions/securityAction";
import {createFurniture, deleteFurniture, updateFurniture} from "../../utils/api/furniture";
import {saveMenuApi, deleteMenuApi, updateMenuApi} from "../../utils/api/menu";
import {checkUserSub} from "../../utils/api/profile";
import {get_class_color} from "../../hook/get_class_color";
import ChoseDirectionModal from "../../modal/choseDirectionModal";
import {
    faCircleInfo,
    faCircleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from 'react-tooltip'
import {faTruckPlane} from "@fortawesome/free-solid-svg-icons/faTruckPlane";
import Loading from "../../components/loading/Loading";
import {BASE_PATH} from "../../constants/apiEndPoint";


const emptyObjectPiece = {
    name: '',
    type: null,
    productBio: null,
    productLocal: null,
    numberOfPieceByRecipe: null,
    pieceGrammes: null,
    knownCo2ByPiece: null,
    knownCo2ByKg: null,
    metreCarre: null,
    metreCube: null,
    distance: null,
    total_area: null,
    localManufacturing: null,
    manufacturingDistance: null,
    recycled: null,
    recipes: [],
    result: [],
    recipeFurnishings: [],
};

const emptyObjectPieceForStandists = {
    name: '',
    type: null,
    productBio: null,
    productLocal: null,
    numberOfPieceByRecipe: 1,
    pieceGrammes: null,
    knownCo2ByPiece: null,
    knownCo2ByKg: null,
    metreCarre: null,
    metreCube: null,
    distance: null,
    total_area: null,
    localManufacturing: null,
    manufacturingDistance: null,
    recycled: null,
    recipes: [],
    result: [],
    recipeFurnishings: [],
};

const emptyObjectType = {
    name: '',
};

export default function Catalogue(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    // const {language} = useSelector(state => state.language)
    const [t] = useTranslation('common')

    const [allPiece, setAllPiece] = useState([]);
    const [paginationPieces, setPaginationPieces] = useState([]);
    const [modalPieceCaterer, setModalPieceCaterer] = useState(false);
    const [modalPieceStandist, setModalPieceStandist] = useState(false);
    const [modalDeletePiece, setModalDeletePiece] = useState(false);

    const [pieceSelected, setPieceSelected] = useState(null);
    const [reload, setReload] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingIsSub, setLoadingIsSub] = useState(true);
    const [loadingTypology, setLoadingTypology,] = useState(false);
    const [loadingIngredient, setLoadingIngredient] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [searchPiece, setSearchPiece] = useState([]);
    const [orderPiece, setOrderPiece] = useState({});
    const [orderIngredient, setOrderIngredient] = useState({});
    const [orderPieceType, setOrderPieceType] = useState({});
    const [orderPieceAgencement, setOrderPieceAgencement] = useState({});
    const [orderMenu, setOrderMenu] = useState({});
    const dataObj = {
        search: "",
        order: "",
        page: ""
    }

    const [allTypes, setallTypes] = useState([]);
    const [typeSelected, setTypeSelected] = useState(null);
    const [modalTypology, setModalTypology] = useState(false);
    const [modalDeleteType, setModalDeleteType] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [choseDirectModal, setChoseDirectModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState(false);
    const [participants, setParticipants] = useState(1);

    const [ingredients, setIngredients] = useState([]);
    const [modalIngredient, setModalIngredient] = useState(false);
    const [modalFurniture, setModalFurniture] = useState(false);
    const [modalMenu, setModalMenu] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState({})

    const [visibleSpace, setVisibleSpace] = useState(false)
    const [visibleM2, setVisibleM2] = useState(false)

    const [selectedMenu, setSelectedMenu] = useState({})
    const [modalDeleteIngredientOrFurniture, setModalDeleteIngredientOrFurniture] = useState(false);
    const [modalDeleteMenu, setModalDeleteMenu] = useState(false);
    const [dataForAverage, setDataForAverage] = useState(false);
    const [onlyMyIngredients, setOnlyMyIngredients] = useState(true);
    const [isCreate, setIsCreate] = useState(true);
    const [isSub, setIsSub] = useState(false);
    const [csvDownload, setCsvDownload] = useState(false);


    const exportDataToCsv = async (type) => {
        setCsvDownload(true);
        const r = await exportTypeToCsv(user.token, type);
        if (r.error == 'Error while exporting'){
            toast.error('No data', TOAST_CONFIG)
            setCsvDownload(false)
            return
        }
        let url = BASE_PATH + r.data;
        let lastIndex = url.lastIndexOf("api");
        // Remplacer la dernière occurrence de "api" par "xlsx"
        let newUrl = url.slice(0, lastIndex) + url.slice(lastIndex + 3);
        window.open(newUrl)
        setCsvDownload(false);
        //on attend que le fichier soit délivré avant de le supprimer
        setTimeout(() => {
            deleteFile(user.token, r.data);
        }, 5000)
    }


    const openModalEditType = (type) => {

        if (type !== '') {
            setIsCreate(false)
        }
        setTypeSelected(type)
        // setModalShow(true)
        setSelectedObject(type)
        setModalTypology(true)
    }

    const saveType = async (type) => {

        if (type.id) {
            await updateType(user.token, type);
            toast.success("Mis à jour", TOAST_CONFIG)
        } else {
            await createType(user.token, type);
            toast.success("Ajouté", TOAST_CONFIG)
        }
        setReload(Date.now());
        setModalShow(false);
    }

    const deleteTypeAction = async () => {
        const r = await deleteType(user.token, typeSelected.id);
        if (r.data?.status === 'error') {
            toast.error(r.data?.data, TOAST_CONFIG);
        } else {
            toast.success("Supprimé", TOAST_CONFIG)
        }
        setReload(new Date())
    }

    const openModalDeleteType = (type) => {
        setTypeSelected(type)
        setModalDeleteType(true)
    }

    const recipesIngredientsId = [];
    const pushRecipesIngredients = () => {
        for (const piece of allPiece) {
            for (const recipe of piece.recipes) {
                recipesIngredientsId.push(recipe.ingredient[0].id)
            }
        }
    }

    const checkIfUserIsSub = async () => {
        setLoadingIsSub(true)
        const data = await checkUserSub(user.token);
        setLoadingIsSub(false)
        setIsSub(data.data.isSub);
    }

    const openModalIngredientsOrFurnitures = () => {
        if (authorization(user, 'ROLE_CATERER')) {
            setModalIngredient(true)
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            setModalFurniture(true)
        }
    }

    const openModalDeleteIngredientOrFurniture = (ingredient) => {
        if (authorization(user, 'ROLE_CATERER')) {
            pushRecipesIngredients();
            if (recipesIngredientsId.includes(ingredient.id)) {
                toast.error('Cet ingrédient est affecté à une recette')
            } else {
                setModalDeleteIngredientOrFurniture(true)
            }
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            setModalDeleteIngredientOrFurniture(true)
        }
    }


    const calculateAverageForTypes = (types, pieces) => {
        if (types) {

            let stackDataPerId = {}

            //the knownCo2ByKg isnt displayed on the left table, it's the result of the footprint probably
            pieces?.forEach(piece => {
                if (!stackDataPerId[piece.type?.id]) {
                    stackDataPerId[piece.type?.id] = {
                        number: 1,
                        kg: piece.result['footprintFor1Kg'] || 0,
                        m2: piece.result['footprintForSquareMeter'] || 0
                    };

                } else {
                    stackDataPerId[piece.type?.id] = {
                        number: stackDataPerId[piece.type?.id].number + 1,
                        kg: stackDataPerId[piece.type?.id].kg + piece.result['footprintFor1Kg'] || 0,
                        m2: stackDataPerId[piece.type?.id].m2 + piece.result['footprintForSquareMeter'] || 0
                    }
                }
            })
            setDataForAverage(stackDataPerId)
        }
    }

    const openModalEditPieceCarterer = (piece) => {
        setPieceSelected(piece)
        setModalPieceCaterer(true)
    }
    const openModalEditPieceStandist = (piece) => {
        setPieceSelected(piece)
        setModalPieceStandist(true);
    }

    const selectorModal = (piece) => {

        if (authorization(user, 'ROLE_CATERER')) {
            openModalEditPieceCarterer(piece)
        }

        if (authorization(user, 'ROLE_STANDIST')) {
            openModalEditPieceStandist(piece)
        }
    }

    const openModalDeletePiece = (piece) => {
        setPieceSelected(piece)
        setModalDeletePiece(true)
    }


    const handleSearchPiece = (search) => {
        setCurrentPagePieces(1)
        setSearchPiece(search)
        setReload(Date.now())
    }

    const duplicatePiece = async (piece) => {

        let copyPiece = {...piece};
        delete copyPiece.id

        await createPiece(user.token, copyPiece);

        setReload(Date.now())
    }

    const getOrder = (key, order) => {

        if (!loading) {

            setLoading(true);
            setOrderPiece({attribute: key, order: order})
            setReload(Date.now());
        }
    };

    const orderIngredients = (key, order) => {

        if (!loadingIngredient) {
            setLoadingIngredient(true);
            setOrderIngredient({attribute: key, order: order})
            setReload(new Date())
        }
    }

    const orderPieceTypes = (key, order) => {
        if (!loadingTypology) {
            setLoadingTypology(true);
            setOrderPieceType({attribute: key, order: order})
            setReload(new Date())
        }
    }


    const orderAgencement = (key, order) => {
        if (!loading) {
            setLoading(true);
            setOrderPieceAgencement({attribute: key, order: order})
            setReload(new Date())
        }
    }

    const orderMenuAction = (key, order) => {
        if (!loading) {
            setLoading(true);
            setOrderMenu({attribute: key, order: order})
            setReload(new Date())
        }
    }

    // *********PAGINATION**********************
    const [currentPageIngredients, setCurrentPageIngredients] = useState(1);
    const [maxPageIngredients, setMaxPageIngredients] = useState(1);

    const [currentPageTypology, setCurrentPageTypology] = useState(1);
    const [maxPageTypology, setMaxPageTypology] = useState(1);

    const [currentPagePieces, setCurrentPagePieces] = useState(1);
    const [maxPagePieces, setMaxPagePieces] = useState(1);

    const [currentPageMenu, setCurrentPageMenu] = useState(1);
    const [maxPageMenu, setMaxPageMenu] = useState(1);

    // Fonction pour gérer le changement de page
    const handlePageChange = (pageNumber) => {
        setCurrentPageIngredients(pageNumber);
    };


    useEffect(async () => {

        if(!localStorage.getItem('DIRECTION_CHOSE')) {
            setChoseDirectModal(true)
        }

        if (authorization(user, 'ROLE_CATERER')) {
            dataObj.search = orderIngredient.attribute;
            dataObj.order = orderIngredient.order;

            const r = await getAllIngredients(user.token, currentPageIngredients, onlyMyIngredients, dataObj);
            setIngredients(r?.data.ingredientListSliced);
            setMaxPageIngredients(r?.data?.totalPages);
            setLoading(false);
            setLoadingTypology(false);
            setLoadingIngredient(false);
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            dataObj.search = orderPieceAgencement.attribute;
            dataObj.order = orderPieceAgencement.order;

            const r = await getAllFurniture(user.token, currentPageIngredients, onlyMyIngredients, dataObj);
            setIngredients(r?.data.furnishingsListSliced);
            setMaxPageIngredients(r?.data?.totalPages);
            setLoading(false);
            setLoadingTypology(false);
            setLoadingIngredient(false);
        }

    }, [reload, currentPageIngredients])

    useEffect(async () => {
        dataObj.search = orderPieceType.attribute;
        dataObj.order = orderPieceType.order;

        getTypeList(user.token, currentPageTypology, dataObj).then((r) => {
            setallTypes(r?.data?.typeListSliced);
            setMaxPageTypology(r?.data?.totalPages)

            setLoading(false);

        })
        checkIfUserIsSub();
    }, [reload, currentPageTypology])

    useEffect(async () => {

        dataObj.search = orderMenu.attribute;
        dataObj.order = orderMenu.order;

        getMenu(user.token, dataObj, currentPageMenu).then((r) => {
            setMenuList(r?.data?.menuListSliced)
            setMaxPageMenu(r?.data?.totalPages)
        })
    }, [reload, currentPageMenu])

    useEffect(async () => {

        dataObj.search = searchPiece;
        dataObj.order = orderPiece;

        let typeSwap = null;

        getTypeList(user.token).then((types) => {
                setTypeList(types?.data?.typeList)
                typeSwap = types;
            }
        );

        getPiece(user.token, dataObj, currentPagePieces).then((r) => {
                setAllPiece(r?.data.piecesList);
                setPaginationPieces(r?.data.piecesListSliced)
                setMaxPagePieces(r?.data?.totalPages)
                if (typeSwap) {
                    calculateAverageForTypes(typeSwap.data.typeList, r?.data.piecesList)
                }
                setLoading(false);
            }

        );


    }, [reload, currentPagePieces])

    function getEmissionFormula(menu) {
        let sum = 0;
        return menu.co2;
    }

    function getEmissionPerParticipant(menu) {
        const sum = getEmissionFormula(menu);
        return sum / participants;
    }

    function getTermForUserRole(key) {


        if (authorization(user, 'ROLE_CATERER')) {

            if (key == 'ingredient') {
                return t('common:catalogue.bloc.title.ingredients');
            }
            if (key == 'add-ingredient') {
                return t('common:catalogue.button.my_ingredients');
            }
            if (key == 'menu') {
                return t('common:catalogue.button.menu');
            }

        }

        if (authorization(user, 'ROLE_STANDIST')) {

            if (key == 'ingredient') {
                return t('common:catalogue.bloc.title.furnishings');
            }
            if (key == 'add-ingredient') {
                return t('common:catalogue.button.my_furnishings');
            }

        }


    }

    function calculateFret(type, piece) {

        if(piece.manufacturingDistance && piece.totalArea) {
            const mappingObj = {
                "Choisir ci-dessous…": 0,
                "+/- 50km de l'event": 0.052,
                "+/- 200km de l'event": 0.052,
                "+/- 600km de l'event": 0.052,
                "+/- 1000km de l'event": 0.052,
                "+/- 3000km de l'event": 0.1458,
                "+/- 5000km de l'event": 0.2324,
                "+/- 10000km de l'event": 0.326,
            };
            const fe = mappingObj[piece.manufacturingDistance];
            const distance = fe === 0 ? 0 : parseInt(piece.manufacturingDistance.match(/\d+(\.\d+)?/)[0]);
            const surface = piece.totalArea
            //formule extraite de : https://gitlab.com/dev-product/climeet_dev/uploads/0ca150aa83b48972b854525f79b07a08/Fret_pre%CC%81visionnel.pdf
            // Exemple : Distance (3000) * FE (0,1458) * Surface (23) / 10 = 1,006.02 kgCO2e

            if(type == 'space') {
                return (distance * fe * surface / 10).toFixed(3);
            } else {
                //Message samy 18/12 : Pour le m2 tu divises par le nb de m2
                return ((distance * fe * surface / 10)/ surface).toFixed(3);
            }
        } else {
            return "X"
        }
    }
    return (<>

        {loadingIsSub && <Loading/>}

        {choseDirectModal &&
            <ChoseDirectionModal closeModal={setChoseDirectModal}></ChoseDirectionModal>
        }

        {!loadingIsSub && !loading && 
        
        <div
            style={{backgroundColor: customization.backgroundColor}}
            className="bg-green-light flex flex-col items-center min-h-screen px-5">
            <h2 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                className="py-3 font-title font-bold text-5xl "><ArrowTitle/>{t('catalogue.title')}</h2>


            {isSub && 
                <div >
                    {/*catalog*/}
                    <div className='flex flex-wrap flex-row mt-10 gap-3.5'>
                        <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                             className='flex flex-col relative blancCasse rounded mb-3 mt-1 p-8  py-4 catalog-bloc'>

                            <div className='flex justify-between'>
                                <span>
                                    <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                        className={'text-2xl'}>{user.roles.includes('ROLE_STANDIST') ? t('catalogue.bloc.title.facilities') : t('catalogue.bloc.title.pieces')}
                                        <FontAwesomeIcon data-tooltip-id="piece-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                        {!csvDownload ?
                                            <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv(user.roles.includes('ROLE_CATERER') ? 'piece' : 'space')}} className={'ml-4 cursor-pointer text-base blancCasse yellowHover pb-0.5'} icon={faFileExport} />
                                            :
                                            <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                        }
                                    </h3>
                                </span>
                                <Tooltip id="piece-tooltip"
                                         textColor={"#fdc96e"}
                                         className={"relative z-50"}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {user.roles.includes('ROLE_STANDIST') ?
                                            <>
                                                <span>{t('catalog.standist.space.a')}</span>
                                                <span>{t('catalog.standist.space.b')}</span>
                                                <span>{t('catalog.standist.space.c')}</span>
                                            </>
                                            :
                                            <>
                                                <span>{t('catalog.caterer.piece.a')}</span>
                                                <span>{t('catalog.caterer.piece.b')}</span>
                                                <span>{t('catalog.caterer.piece.c')}</span>
                                            </>
                                        }
                                    </div>
                                </Tooltip>
                                <AddPieceButton
                                    openModalEditPiece={selectorModal}
                                />
                            </div>

                            {/*search*/}
                            <div className='search_catalog'>
                                <input type='search' placeholder={t('catalogue.bloc.catalog.search')} id='input-search_catalog'
                                       className=''
                                       onChange={(e) => {
                                           const searchQuery = e.target.value;
                                           if (searchQuery.length >= 3 || searchQuery.length === 0) {
                                               handleSearchPiece(searchQuery);
                                           }
                                       }}
                                />
                                <FontAwesomeIcon className="mr-3 blue-unlock glass" icon={faSearch}/>
                            </div>

                            <div className='mt-8'>


                                {!loading &&
                                    <table className={'w-full'}>
                                        <thead>
                                        <tr>
                                            <th className={'px-2 text-md'}>
                                                <div className={'flex mb-2'}>
                                                    <p>{t('eventList.table.name')}</p>
                                                    <div className={'flex flex-col px-2 text-xs'}>
                                                        <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                         onClick={() => {
                                                                             getOrder('name', 'asc')
                                                                         }} icon={faChevronUp}/>
                                                        <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                         onClick={() => {
                                                                             getOrder('name', 'desc')
                                                                         }} icon={faChevronDown}/>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className={'px-2 text-md'}>
                                                <div className={'flex mb-2'}>
                                                    <p>{t('eventList.table.type')}</p>
                                                    <div className={'flex flex-col px-2 text-xs'}>
                                                        <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                         onClick={() => {
                                                                             getOrder('type', 'asc')
                                                                         }} icon={faChevronUp}/>
                                                        <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                         onClick={() => {
                                                                             getOrder('type', 'desc')
                                                                         }} icon={faChevronDown}/>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className={'px-2 text-md'}>
                                                <div className={'flex mb-2'}>
                                                    <p>{!user.roles.includes('ROLE_STANDIST') ? t('common:catalogue.bloc.catalog.kgCO2PieceStandist') :t('common:catalogue.bloc.catalog.kgCO2Piece')}</p>
                                                    <div className={'flex flex-col px-2 text-xs'}>
                                                        <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                         onClick={() => {
                                                                             getOrder('footprintByPiece', 'asc')
                                                                         }} icon={faChevronUp}/>
                                                        <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                         onClick={() => {
                                                                             getOrder('footprintByPiece', 'desc')
                                                                         }} icon={faChevronDown}/>
                                                    </div>
                                                    {user.roles.includes('ROLE_STANDIST') &&
                                                        <>
                                                            <div className={'flex flex-col px-2 text-xs'}
                                                                 onMouseEnter={() => {setVisibleSpace(true)}}
                                                                 onMouseLeave={() => {setVisibleSpace(false)}}
                                                                 data-tooltip-id={'fretSpace'} data-tooltip-content={t('catalogue.bloc.catalog.fretSpace')}
                                                            >
                                                                <FontAwesomeIcon style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className="h-5 mr-3 blue-unlock cursor-pointer"
                                                                                 icon={faTruckPlane}/>
                                                            </div>
                                                            <Tooltip id={'fretSpace'} multiline={true} className="tooltip max-w-xs rounded relative z-50" textColor={"#fdc96e"}/>
                                                        </>
                                                    }

                                                </div>
                                            </th>
                                            {authorization(user, 'ROLE_CATERER') &&
                                                <th className={'px-2 text-md'}>
                                                    <div className={'flex mb-2'}>
                                                        <p>kgCO2e/kg</p>
                                                        <div className={'flex flex-col px-2 text-xs'}>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('footprintFor1Kg', 'asc')
                                                                             }} icon={faChevronUp}/>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('footprintFor1Kg', 'desc')
                                                                             }} icon={faChevronDown}/>
                                                        </div>
                                                    </div>
                                                </th>}

                                            {authorization(user, 'ROLE_STANDIST') &&
                                                <th className={'px-2 text-md'}>
                                                    <div className={'flex mb-2'}>
                                                        <p>kgCO2e/m2</p>
                                                        <div className={'flex flex-col px-2 text-xs'}>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('footprintFor1Kg', 'asc')
                                                                             }} icon={faChevronUp}/>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('footprintFor1Kg', 'desc')
                                                                             }} icon={faChevronDown}/>
                                                        </div>
                                                        <div className={'flex flex-col px-2 text-xs'}
                                                             data-tooltip-id={'fretM2'} data-tooltip-content={t('catalogue.bloc.catalog.fretM2')}
                                                             onMouseEnter={() => {setVisibleM2(true)}}
                                                             onMouseLeave={() => {setVisibleM2(false)}}
                                                        >
                                                            <FontAwesomeIcon style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className="h-5 mr-3 blue-unlock cursor-pointer"
                                                                             icon={faTruckPlane}/>
                                                        </div>
                                                        <Tooltip id={'fretM2'} multiline={true} className="tooltip max-w-xs rounded relative z-50" textColor={"#fdc96e"}/>
                                                    </div>
                                                </th>}

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {paginationPieces?.length !== 0 && paginationPieces?.map((piece) => {
                                            return <tr className={'hover:opacity-40'} key={piece.id}>
                                                <td className="px-2 text-md truncate" style={{maxWidth: '190px'}}
                                                    title={piece.name}>{piece.name}</td>
                                                <td className={'px-2 text-md truncate'}
                                                    style={{maxWidth: '140px'}}
                                                    title={piece?.type?.name}>{piece?.type?.name}</td>
                                                <td className={'px-2 text-md eventEllipsis'}>
                                                    <span>{piece.result.footprintByPiece}</span>
                                                    {authorization(user, 'ROLE_STANDIST') && visibleSpace &&
                                                        <span style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className={'visibleSpace float-right pr-10'}>{calculateFret('space', piece)}</span>
                                                    }
                                                </td>

                                                {authorization(user, 'ROLE_CATERER') &&
                                                    <td className={'px-2 text-md eventEllipsis'}>{piece.result.footprintFor1Kg}</td>
                                                }

                                                {authorization(user, 'ROLE_STANDIST') &&
                                                    < td
                                                        className={'px-2 text-md eventEllipsis'}>
                                                        <span>{piece.result.footprintForSquareMeter}</span>
                                                        {visibleM2 &&
                                                            <span style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className={'visibleSpace float-right pr-10'}>{calculateFret('m2', piece)}</span>
                                                        }
                                                    </td>
                                                }

                                                <td className={'text-md w-0.5'}>
                                                    <FontAwesomeIcon className={'mr-4 mt-2 cursor-pointer yellowHover'} icon={faPen}
                                                                     onClick={() => {
                                                                         selectorModal(piece)
                                                                     }}/>
                                                </td>
                                                <td className={'text-md w-0.5'}>
                                                    <FontAwesomeIcon className={'mr-4 mt-2 cursor-pointer yellowHover'} icon={faCopy}
                                                                     onClick={() => {
                                                                         duplicatePiece(piece)
                                                                     }}/>
                                                </td>
                                                <td className={'text-md w-0.5'}>
                                                    <FontAwesomeIcon className={'mt-2 cursor-pointer yellowHover'} icon={faTrash}
                                                                     onClick={() => {
                                                                        openModalDeletePiece(piece)
                                                                     }}/>
                                                </td>
                                            </tr>
                                        })}

                                        </tbody>
                                    </table>}
                            </div>

                            {/*****************PAGINATION PIECES***********************/}

                            {paginationPieces?.length !== 0 &&
                                <Pagination
                                    currentPage={currentPagePieces}
                                    totalPages={maxPagePieces}
                                    onPageChange={setCurrentPagePieces}
                                    withButtons={true}
                                    setCurrentPage={setCurrentPagePieces}
                                />
                            }
                        </div>

                        {modalShow ?
                            <ConfirmModal reloadAction={setReload} inputName={'Name'} title={t('catalogue.button.typology')}
                                          confirmBtn={t('catalogue.button.add')} selectedObject={selectedObject}
                                          finishAction={saveType} closeModal={setModalShow}/> : null}


                        <div className='flex md:w-auto flex-col typologyIngredient-bloc'>
                            {/******************************************TYPOLOGY***********************************************************************/}
                            <div className={'flex flex-row gap-2'}>
                                <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                     className='flex flex-col relative w-3/4 blancCasse rounded mb-3 mt-1 basis-2/4 mb-8 py-4 typology-bloc'>

                                    <div className='flex justify-between mx-2 px-5'>
                                        <span>
                                        <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                            className={'text-2xl'}>{t('common:catalogue.bloc.title.typology')}
                                            <FontAwesomeIcon data-tooltip-id="type-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                            {/* {!csvDownload ?
                                                <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv('type')}} className={'ml-4 cursor-pointer text-base blancCasse yellowHover pb-0.5'} icon={faFileExport} />
                                                :
                                                <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                            } */}
                                        </h3>
                                        </span>
                                        <Tooltip id="type-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {user.roles.includes('ROLE_STANDIST') ?
                                                    <>
                                                        <span>{t('catalog.standist.type.a')}</span>
                                                        <span>{t('catalog.standist.type.b')}</span>
                                                        <span>{t('catalog.standist.type.c')}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span>{t('catalog.caterer.type.a')}</span>
                                                        <span>{t('catalog.caterer.type.b')}</span>
                                                        <span>{t('catalog.caterer.type.c')}</span>
                                                    </>
                                                }
                                            </div>
                                        </Tooltip>
                                        <button onClick={() => {
                                            openModalEditType('')
                                        }}
                                                className="text-lg bg-blue-600 ml-5 border border-blue-500 font-bold  px-8 flex justify-center btn-create">
                                            {t('common:catalogue.button.typology')}
                                        </button>
                                    </div>

                                    {modalTypology ?
                                        <ConfirmModal reloadAction={setReload} inputName={t('catalogue.bloc.typology.name')}
                                                      title={t('catalogue.button.typology')} isCreate={isCreate}
                                                      confirmBtn={t('catalogue.button.add')}
                                                      selectedObject={selectedObject} finishAction={saveType}
                                                      closeModal={() => {
                                                          setModalTypology(false);
                                                          setReload(Date.now())
                                                      }}/> : null}

                                    <div className='mt-5 px-5'>
                                        {!loadingTypology &&
                                            <table className={'w-full'}>
                                                <thead>
                                                <tr>
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex mb-2'}>
                                                            <p>{t('eventList.table.name')}</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderPieceTypes('name', 'ASC')
                                                                                 }}
                                                                                 icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderPieceTypes('name', 'DESC')
                                                                                 }}
                                                                                 icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>

                                                    <th className={'px-2 text-md '}>
                                                        <div className={'flex mb-2'}>

                                                            {authorization(user, 'ROLE_CATERER') &&
                                                                <p>{t('common:catalogue.bloc.typology.kgCO2average')}</p>}
                                                            {authorization(user, 'ROLE_STANDIST') &&
                                                                <p>{t('common:catalogue.bloc.typology.kgCO2averageByM2')}</p>}


                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderPieceTypes('average', 'ASC')
                                                                                 }}
                                                                                 icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderPieceTypes('average', 'DESC')
                                                                                 }}
                                                                                 icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {allTypes?.length !== 0 && allTypes?.map((type) => {

                                                    return <tr className={'hover:opacity-40'} key={type.id}>
                                                        <td className={'px-2 text-md whitespace-nowrap overflow-hidden overflow-ellipsis'}
                                                            style={{maxWidth: '140px'}} title={type.name}>{type.name}</td>

                                                        {authorization(user, 'ROLE_CATERER') &&
                                                            <td className={'px-2 text-md w-52'}>{

                                                                dataForAverage[type.id] &&
                                                                (dataForAverage[type.id].kg / dataForAverage[type.id].number).toFixed(3)
                                                            }</td>
                                                        }

                                                        {authorization(user, 'ROLE_STANDIST') &&
                                                            <td className={'px-2 text-md w-52'}>{

                                                                dataForAverage[type.id] &&
                                                                (dataForAverage[type.id].m2 / dataForAverage[type.id].number).toFixed(3)
                                                            }</td>
                                                        }


                                                        <td className={'text-md w-0.5'}>
                                                            <FontAwesomeIcon className={'mr-4 mt-2 cursor-pointer yellowHover'} icon={faPen}
                                                                             onClick={() => {
                                                                                 openModalEditType(type)
                                                                             }}/>
                                                        </td>
                                                        <td className={'text-md w-0.5'}>
                                                            <FontAwesomeIcon className={'mt-2 cursor-pointer yellowHover'} icon={faTrash}
                                                                             onClick={() => {
                                                                                 openModalDeleteType(type)
                                                                             }}/>
                                                        </td>
                                                    </tr>
                                                })}

                                                </tbody>
                                            </table>}
                                    </div>
                                    {/*****************PAGINATION TYPOLOGY***********************/}

                                    {allTypes.length !== 0 &&
                                        < Pagination
                                            currentPage={currentPageTypology}
                                            totalPages={maxPageTypology}
                                            onPageChange={setCurrentPageTypology}
                                        />
                                    }

                                </div>

                                {/*********************************************INGREDIENTS***********************************************************/}
                                <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                     className='flex flex-col relative w-3/4 blancCasse rounded mb-3 mt-1 basis-2/4 py-4 ingredients-bloc'>

                                    <div className='flex justify-between mx-2 px-5'>
                                        <span>
                                            <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                                className={'text-2xl'}>{getTermForUserRole('ingredient')}
                                                <FontAwesomeIcon data-tooltip-id="ingredient-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                                {!csvDownload ?
                                                    <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv(user.roles.includes('ROLE_CATERER') ? 'ingredient' : 'item')}} className={'ml-4 cursor-pointer text-base blancCasse yellowHover pb-0.5'} icon={faFileExport} />
                                                    :
                                                    <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                                }
                                            </h3>
                                        </span>
                                        <Tooltip id="ingredient-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {user.roles.includes('ROLE_STANDIST') ?
                                                    <>
                                                        <span>{t('catalog.standist.item.a')}</span>
                                                        <span>{t('catalog.standist.item.b')}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span>{t('catalog.caterer.ingredient.a')}</span>
                                                        <span>{t('catalog.caterer.ingredient.b')}</span>
                                                        <span>{t('catalog.caterer.ingredient.c')}</span>
                                                    </>
                                                }
                                            </div>
                                        </Tooltip>
                                        <Tooltip id="extract-csv" textColor={"#fdc96e"} className="relative z-50">
                                            <div>
                                                <span>{t('global.extractToCSV')}</span>
                                            </div>
                                        </Tooltip>
                                        <button
                                            onClick={() => {
                                                setSelectedIngredient({})
                                                openModalIngredientsOrFurnitures()
                                            }}
                                            className={'btn-create text-lg border border-green-500 font-bold py-2 px-8 flex justify-center'}>
                                            {getTermForUserRole('add-ingredient')}
                                        </button>
                                    </div>

                                    <div className='mt-5 px-5'>
                                        {!loadingIngredient &&
                                            <table className={'w-full'}>
                                                <thead>
                                                <tr>
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex mb-2'}>
                                                            <p>{t('eventList.table.name')}</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderAgencement('name', 'ASC')
                                                                                 }}
                                                                                 icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderAgencement('name', 'DESC')
                                                                                 }}
                                                                                 icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex mb-2'}>
                                                            <p>FE kgCO2e/{t('ingredients.unite')}</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderAgencement('kgCO2eBykg', 'ASC')
                                                                                 }}
                                                                                 icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderAgencement('kgCO2eBykg', 'DESC')
                                                                                 }}
                                                                                 icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {ingredients?.length !== 0 && ingredients?.map((ingredient) => {
                                                    return <tr className={'hover:opacity-40'} key={ingredient.id}>
                                                        <td className={'px-2 text-md flex'}><span
                                                            className={'whitespace-nowrap overflow-hidden overflow-ellipsis ingredient-name mr-1'}
                                                            title={ingredient.name}>{ingredient.name}</span><span>({ingredient.unite || 0})</span>
                                                        </td>
                                                        <td className={'px-2 text-md w-52'}>{ingredient?.kgCO2eBykg || parseInt(ingredient?.ef, 10).toFixed(3)}</td>
                                                        <td className={'text-md w-0.5'}>
                                                            <FontAwesomeIcon className={'mr-4 mt-2 yellowHover cursor-pointer'} icon={faPen}
                                                                             onClick={() => {
                                                                                 setSelectedIngredient(ingredient)
                                                                                 openModalIngredientsOrFurnitures()
                                                                             }}/>
                                                        </td>
                                                        <td className={'text-md w-0.5'}>
                                                            <FontAwesomeIcon className={'mt-2 yellowHover cursor-pointer'} icon={faTrash}
                                                                             onClick={() => {
                                                                                 setSelectedIngredient(ingredient)
                                                                                 openModalDeleteIngredientOrFurniture(ingredient)
                                                                             }}/>
                                                        </td>
                                                    </tr>
                                                })}

                                                </tbody>
                                            </table>}
                                    </div>

                                    {/*****************PAGINATION INGREDIENTS***********************/}

                                    {ingredients?.length !== 0 &&
                                        <Pagination
                                            currentPage={currentPageIngredients}
                                            totalPages={maxPageIngredients}
                                            onPageChange={handlePageChange}
                                        />
                                    }

                                </div>
                            </div>

                            {/***************DEBUT MENU******************************/}
                            {user.roles.includes('ROLE_CATERER') &&
                                <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                     className='flex flex-col relative w-full blancCasse rounded mb-3 h-full mt-1 basis-1/4 py-4 menu-bloc'>

                                    <div className='flex justify-between mx-2 px-5'>
                                        <span>
                                            <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                                className={'text-2xl'}>{getTermForUserRole('menu')}
                                                <FontAwesomeIcon data-tooltip-id="menu-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                                {!csvDownload ?
                                                    <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv('menu')}} className={'blancCasse yellowHover ml-4 cursor-pointer text-base pb-0.5'} icon={faFileExport} />
                                                    :
                                                    <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                                }
                                                {/* <span data-tooltip-id="my-tooltip" data-tooltip-content={authorization(user, 'ROLE_CATERER') ? t("choseDirection.tooltip_1a") : t("choseDirection.tooltip_1")} > */}
                                            </h3>
                                        </span>
                                        <Tooltip id="menu-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>{t('catalog.caterer.menu.a')}</span>
                                                <span>{t('catalog.caterer.menu.b')}</span>
                                            </div>
                                        </Tooltip>
                                        <div className={'ml-72'}>
                                            <label htmlFor="" className={'mr-5'}>{t('menu.nb_participants')}</label>
                                            <input type="text" className='w-20 text-black'
                                                   defaultValue={participants}
                                                   onChange={(e) => {
                                                       setParticipants(e.target.value)
                                                   }}
                                            />
                                        </div>
                                        <button
                                            onClick={() => {
                                                setSelectedMenu({})
                                                setModalMenu(true)
                                            }}
                                            className={'btn-create text-lg border border-green-500 font-bold py-2 px-8 flex justify-center'}>
                                            {getTermForUserRole('add-ingredient')}
                                        </button>
                                    </div>

                                    <div className='mt-5 px-5'>
                                        {!loadingIngredient &&
                                            <table className={'w-full'}>
                                                <thead>
                                                <tr>
                                                    <th className={'px-2 text-md w-80'}>
                                                        <div className={'flex mb-2'}>
                                                            <p>{t('eventList.table.formula')}</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderMenuAction('name', 'ASC')
                                                                                 }}
                                                                                 icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderMenuAction('name', 'DESC')
                                                                                 }}
                                                                                 icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex mb-2'}>
                                                            <p>kgCO2e/menu</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderMenuAction('kgCO2eBykg', 'ASC')
                                                                                 }}
                                                                                 icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     orderMenuAction('kgCO2eBykg', 'DESC')
                                                                                 }}
                                                                                 icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex mb-2'}>
                                                            <p>kgCO2e/participant</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {menuList?.length !== 0 && menuList?.map((menu) => {
                                                    return <tr className={'hover:opacity-40'} key={menu.id}>
                                                        <td className={'px-2 text-md flex'}><span
                                                            className={'whitespace-nowrap overflow-hidden overflow-ellipsis ingredient-name mr-1'}
                                                            title={menu.name}>{menu.name}</span>
                                                        </td>
                                                        <td className={'px-2 text-md w-52'}>{getEmissionFormula(menu).toFixed(3)}</td>
                                                        <td className={'px-2 text-md w-52'}>{participants && getEmissionPerParticipant(menu) > 0 ? getEmissionPerParticipant(menu).toFixed(3) : Number(0).toFixed(3)}</td>
                                                        <td className={'text-md w-0.5'}>
                                                            <FontAwesomeIcon className={'mr-4 mt-2 yellowHover cursor-pointer'} icon={faPen}
                                                                             onClick={() => {
                                                                                 setSelectedMenu(menu)
                                                                                 setModalMenu(true)
                                                                             }}/>
                                                        </td>
                                                        <td className={'text-md w-0.5'}>
                                                            <FontAwesomeIcon className={'mt-2 yellowHover cursor-pointer'} icon={faTrash}
                                                                             onClick={() => {
                                                                                 setSelectedMenu(menu)
                                                                                 setModalDeleteMenu(true)
                                                                             }}/>
                                                        </td>
                                                    </tr>
                                                })}

                                                </tbody>
                                            </table>}
                                    </div>
                                    <div className={'mt-5'}>
                                        {menuList?.length !== 0 &&
                                            <Pagination
                                                currentPage={currentPageMenu}
                                                totalPages={maxPageMenu}
                                                onPageChange={setCurrentPageMenu}
                                            />
                                        }
                                    </div>

                                </div>
                            }
                            {/***************FIN MENU******************************/}
                        </div>

                        {modalPieceCaterer &&
                            <ModalPieceCaterer
                                piece={pieceSelected}
                                reload={setReload}
                                typeList={typeList}
                                setModalPiece={setModalPieceCaterer}/>
                        }

                        {modalPieceStandist &&
                            <ModalPieceStandist
                                piece={pieceSelected}
                                reload={setReload}
                                typeList={typeList}
                                setModalPiece={setModalPieceStandist}/>
                        }

                        {modalDeletePiece &&
                            <DeleteModalPiece
                                piece={pieceSelected}
                                reload={setReload}
                                setModalDeletePiece={setModalDeletePiece}/>
                        }

                        {modalIngredient &&
                            <ModalAddIngredient
                                setModalIngredient={setModalIngredient}
                                setIngredients={setIngredients}
                                selectedIngredient={selectedIngredient}
                                setReload={setReload}
                            />
                        }

                        {modalMenu &&
                            <ModalAddMenu
                                paginationPieces={allPiece}
                                setModalMenu={setModalMenu}
                                setSelectedMenu={setSelectedMenu}
                                selectedMenu={selectedMenu}
                                setReload={setReload}
                            />
                        }

                        {modalFurniture &&
                            <ModalAddFurniture
                                setModalFurniture={setModalFurniture}
                                setFurniture={setIngredients}
                                selectedFurniture={selectedIngredient}
                                setReload={setReload}
                            />
                        }

                        {modalDeleteMenu &&
                            <DeleteModalMenu
                                selectedMenu={selectedMenu}
                                setModalDelete={setModalDeleteMenu}
                                setSelectedMenu={setSelectedMenu}
                                reload={setReload}
                            />
                        }

                        {modalDeleteIngredientOrFurniture &&
                            <DeleteModalIngredientOrFurniture
                                selectedIngredient={selectedIngredient}
                                setModalDelete={setModalDeleteIngredientOrFurniture}
                                reload={setReload}
                            />
                        }

                        {/*{modalTypology &&*/}
                        {/*<ModalTypology*/}
                        {/*    piece={typeSelected}*/}
                        {/*    reload={setReload}*/}
                        {/*    setModalType={setModalTypology}/>*/}
                        {/*}*/}

                        {modalDeleteType &&
                            <DeleteModal closeModal={setModalDeleteType} deleteAction={deleteTypeAction}
                                         text={'Etes-vous sûr de vouloir supprimer ce type ?'}/>
                            // <DeleteModalType
                            //     piece={typeSelected}
                            //     reload={setReload}
                            //     setModalDeletePiece={setModalDeleteType}/>
                        }
                    </div>
                </div>
            }
            { !isSub && (
                <div className={"mt-100"}>
                    <div className={'h-screen'}>
                        <p className="blancCasse text-center mt-48">{t('global.subscribe')}</p>
                        {/* <p className={'text-center mt-4'}><a className={'cursor-pointer btn-create'}
                                                                href="/subscription">{t('topbar.subscription')}</a>
                        </p> */}
                    </div>
                </div>
            )}
        </div>
        }
    </>)

}


function AddPieceButton(props) {
    const [t] = useTranslation("common");


    return (<>
        <button
            onClick={() => {
                props.openModalEditPiece(null)
            }}
            className="text-lg bg-blue-600 ml-5 border border-blue-500 font-bold  px-8 flex justify-center btn-create">
            {t('common:catalogue.button.piece')}
        </button>

    </>);

}

function ModalPieceCaterer(props) {


    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [piece, setPiece] = useState(emptyObjectPiece)
    const [displayKnowImpact, setDisplayKnowImpact] = useState(false)

    const optionList = [t('piece.optionList.one'), t('piece.optionList.two'), t('piece.optionList.three'), t('piece.optionList.four')];

    useEffect(() => {

        if (props.piece) {
            setPiece(props.piece);
            if(props.piece.knownCo2ByPiece || props.piece.knownCo2ByKg) { //si l'une ou l'autre des valeurs est définie, on active la checkbox
                setDisplayKnowImpact(true)
            }
        } else {
            piece.recipes = []; //avoid recipes datas persist when create
        }

    }, [])


    const savePiece = async () => {
        piece.mode = 'caterer';
        if (!piece.name) {
            toast.error(t('piece.create_error'))
        } else {

            //si décoché on force le 0
            if(!displayKnowImpact) {
                piece.knownCo2ByKg = 0;
                piece.knownCo2ByPiece = 0;
            }

            if (piece.id) {
                await updatePiece(user.token, piece);
                toast.success("Pièce mise à jour", TOAST_CONFIG);
            } else {
                await createPiece(user.token, piece);
                toast.success("Pièce créée", TOAST_CONFIG);
            }

            props.reload(Date.now());
        }

    }


    const changeLabelButton = () => {
        return !piece.id ? t('piece.add') : t('piece.update');

    }

    const changeLabelTitleModal = () => {
        return !piece.id ? t('piece.add_modal_title') : t('piece.update_modal_title');
    }


    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black" onClick={() => props.setModalPiece(false)}></div>
            <div
                className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-red"
                onClick={() => props.setModalPiece(false)}
            >
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="z-51 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
                        onClick={(e) => {e.stopPropagation()}}>
                        <div className="flex justify-end pt-2 pl-2 pr-2 pb-0">
                            <span className="font-bold blancCasse cursor-pointer yellowHover mr-4 mt-2" onClick={() => props.setModalPiece(false)}><FontAwesomeIcon icon={faXmark} /></span>
                        </div>
                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{changeLabelTitleModal()}</h1>
                        {/*body*/}

                        <p className={'text-black font-semibold  text-center mb-6'}>{t('piece.parameters')}</p>

                        <div className="relative p-6 flex-auto">


                            <div id='form-piece'>
                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.name')}</label>
                                    <input type={'text'} value={piece.name} style={{width: "200px"}}
                                           onChange={(e) => setPiece({...piece, ...{name: e.target.value}})}
                                    />
                                </div>

                                <div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.piece_type')}</label>
                                    <ChoiceInput
                                        name="type"
                                        style={{width: "200px"}}
                                        value={piece.type?.id}
                                        onChange={(e) => setPiece({...piece, ...{type: {id: e.target.value}}})}
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                    >
                                        <option value="null">Aucun</option>
                                        {props.typeList.map(option => (
                                            <option key={option.name} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </ChoiceInput>
                                </div>

                                <div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.product_bio')}</label>
                                    <ChoiceInput
                                        style={{width: "200px"}}
                                        value={piece.productBio}
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                        onChange={(e) => setPiece({...piece, ...{productBio: e.target.value}})}
                                    >

                                        {optionList.map(option => <option key={option.id}
                                                                          className={'force-text-black'}
                                                                          value={option}>{option}</option>)}

                                    </ChoiceInput>
                                </div>


                                <div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2 text-right'}
                                    >
                                        {t('piece.product_local')}
                                        <span className={'ml-3'}>
                                        <FontAwesomeIcon className="tooltip-icon"
                                                         icon={faCircleInfo}
                                                         data-tooltip-id="tooltip_local_product" 
                                        />
                                    </span>    
                                    </label>
                                    <Tooltip id={"tooltip_local_product"} multiline={true} className="tooltip max-w-xs rounded" textColor={"#fdc96e"}>
                                        {t('piece.tooltip.local_product')}
                                    </Tooltip>
                                    <ChoiceInput
                                        style={{width: "200px"}}
                                        value={piece.productLocal}
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                        onChange={(e) => setPiece({...piece, ...{productLocal: e.target.value}})}
                                    >

                                        {optionList.map(option => <option key={option.id}
                                                                          className={'force-text-black '}
                                                                          value={option}>{option}</option>)}

                                    </ChoiceInput>
                                </div>

                                <div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.number')}</label>
                                    <input type={'number'} min='0' style={{width: "200px"}}
                                           value={piece.numberOfPieceByRecipe}
                                           onChange={(e) => setPiece({...piece, ...{numberOfPieceByRecipe: e.target.value}})}
                                    />
                                </div>

                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.weight')}</label>
                                    <input type={'number'} min='0' style={{width: "200px"}}
                                           value={piece.pieceGrammes}
                                           onChange={(e) => setPiece({...piece, ...{pieceGrammes: e.target.value}})}
                                    />
                                </div>

                                <div className={'mb-2'}>
                                    <label htmlFor=""
                                           className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.know_impact')}</label>
                                    <input type="checkbox"
                                           checked={displayKnowImpact}
                                           onChange={(e) => setDisplayKnowImpact(e.target.checked)}
                                    />
                                </div>


                                {displayKnowImpact &&
                                    <>
                                        <div className={'mb-2'}>
                                            <label
                                                className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.known_CO2_by_piece')}</label>
                                            <input type={'number'} min='0' style={{width: "200px"}}
                                                   value={piece.knownCo2ByPiece}
                                                   onChange={(e) => setPiece({...piece, ...{knownCo2ByPiece: e.target.value}})}
                                            />
                                        </div>

                                        <div className={'mb-2'}>
                                            <label
                                                className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.known_CO2_by_kg')}</label>
                                            <input type={'number'} min='0' style={{width: "200px"}}
                                                   value={piece.knownCo2ByKg}
                                                   onChange={(e) => setPiece({...piece, ...{knownCo2ByKg: e.target.value}})}
                                            />
                                        </div>
                                    </>
                                }


                            </div>


                        </div>


                        <div>

                            <AddRecipeCaterer
                                piece={piece}
                                setPiece={setPiece}
                            />
                        </div>


                        {/*footer*/}
                        <div
                            className="flex items-center mt-10 justify-center p-6 border-t border-solid border-slate-200 rounded-b">


                            <button
                                className="text-red-500 font-bold  px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalPiece(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className=" btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    savePiece()
                                    props.setModalPiece(false)
                                }}
                            >
                                {changeLabelButton()}
                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}


function ModalPieceStandist(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [piece, setPiece] = useState(emptyObjectPieceForStandists)

    useEffect(() => {

        if (props.piece) {
            setPiece(props.piece);
        } else {
            piece.recipeFurnishings = [] //avoid recipes datas persist when create
            piece.recipes = []; // same
        }

    }, [props.piece])


    const savePiece = async () => {
        // console.log(piece);
        // return
        piece.mode = 'standist';
        if (!piece.name) {
            toast.error(t('facility.error'))
        } else {
            if (piece.id) {
                await updatePiece(user.token, piece);
                toast.success(t('facility.update'), TOAST_CONFIG);
            } else {
                await createPiece(user.token, piece);
                toast.success(t('facility.create'), TOAST_CONFIG);
            }

            props.reload(Date.now());
        }
    }


    const changeLabelButton = () => {

        // console.log(piece);
        return !piece.id ? t('piece.add') : t('piece.update');

    }

    const changeLabelTitleModal = () => {
        // console.log(piece);
        if(authorization(user, 'ROLE_STANDIST')){
            return !piece.id ? t('piece.add_modal_title_standist') : t('piece.update_modal_title_standist');
        }
        if(authorization(user, 'ROLE_CATERER')){
            return !piece.id ? t('piece.add_modal_title') : t('piece.update_modal_title');
        }
    }

    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black" onClick={() => props.setModalPiece(false)}></div>
            <div
                className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalPiece(false)}
            >
                <div className="relative w-full my-6 mx-auto max-w-4xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex justify-end pt-2 pl-2 pr-2 pb-0">
                            <span className="font-bold blancCasse cursor-pointer yellowHover mr-4 mt-2" onClick={() => props.setModalPiece(false)}><FontAwesomeIcon icon={faXmark} /></span>
                        </div>

                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{changeLabelTitleModal()}</h1>
                        {/*body*/}

                        <p className={'text-black font-semibold  text-center mb-6'}>{t('piece.parameters')}</p>

                        <div className=" p-6 flex-auto">


                            <div id='form-piece'>
                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.name')}</label>
                                    <input type={'text'} value={piece.name} style={{width: "200px"}}
                                           onChange={(e) => setPiece({...piece, ...{name: e.target.value}})}
                                    />
                                </div>

                                <div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.piece_type')}</label>
                                    <ChoiceInput
                                        name="type"
                                        style={{width: "200px"}}
                                        value={piece.type?.id}
                                        onChange={(e) => setPiece({...piece, ...{type: {id: e.target.value}}})}
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                    >
                                        <option value="null">Aucun</option>
                                        {props.typeList.map(option => (
                                            <option key={option.name} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </ChoiceInput>
                                </div>

                                {/*//fabrication locale*/}
                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2 text-right'}>
                                        {t('piece.local_manufacturing')}
                                    </label>
                                    <ChoiceInput
                                        name="type"
                                        style={{width: "200px"}}
                                        value={piece.localManufacturing || t('piece.list.local_manufacturing.1')}
                                        onChange={(e) => {
                                            setPiece({...piece, ...{localManufacturing: e.target.value}})
                                        }
                                        }
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                    >
                                        {
                                            GenerateOptions(
                                                t('piece.list.local_manufacturing', {returnObjects: true}),
                                                t('piece.list.local_manufacturing', {returnObjects: true, lng: 'fr'}),
                                                props.premium,
                                                true
                                            )
                                        }

                                    </ChoiceInput>
                                </div>

                                {/*Distance de l'évent*/}
                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2 text-right'}>
                                        {t('piece.distance')}
                                    </label>
                                    <ChoiceInput
                                        name="manufacturing_distance"
                                        style={{width: "200px"}}
                                        value={piece.manufacturingDistance}
                                        onChange={(e) => setPiece({...piece, ...{manufacturingDistance: e.target.value}})}
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                    >
                                        {/*Génère les options en fonction du choix pour fabrication locale*/}
                                        {piece.localManufacturing === 'Yes' || piece.localManufacturing === 'Oui' ? (
                                            GenerateOptions(
                                                t('piece.list.manufacturing_distance_yes', {returnObjects: true}),
                                                t('piece.list.manufacturing_distance_yes', {
                                                    returnObjects: true,
                                                    lng: 'fr'
                                                }),
                                                props.premium,
                                                true
                                            )
                                        ) : (
                                            GenerateOptions(
                                                t('piece.list.manufacturing_distance_no', {returnObjects: true}),
                                                t('piece.list.manufacturing_distance_no', {
                                                    returnObjects: true,
                                                    lng: 'fr'
                                                }),
                                                props.premium,
                                                true
                                            )
                                        )
                                        }
                                    </ChoiceInput>
                                </div>

                                {/*recycled*/}
                                <div className={'mb-2'}>
                                <span data-tooltip-id="percentRecycled-tooltip" className={'inline-block w-1/2 mr-2 text-right'}>
                                    <label>{t('piece.recycled')}</label>
                                    {user.roles.includes('ROLE_STANDIST') &&
                                        <FontAwesomeIcon className={'ml-2 text-base text-[#fdc96e]'} icon={faCircleInfo} />
                                    }
                                </span>
                                    {user.roles.includes('ROLE_STANDIST') &&
                                        <Tooltip id="percentRecycled-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>{t('catalog.standist.space.percentRecycledSourceA')}</span>
                                                <span>{t('catalog.standist.space.percentRecycledSourceB')}</span>
                                                <span>{t('catalog.standist.space.percentRecycledSourceC')}</span>
                                            </div>
                                        </Tooltip>
                                    }
                                    <ChoiceInput
                                        name="recycled"
                                        style={{width: "200px"}}
                                        value={piece.recycled}
                                        onChange={(e) => setPiece({...piece, ...{recycled: e.target.value}})}
                                        className={'force-text-black'}
                                        textColorClass={"select-text-color-black"}
                                    >
                                        {
                                            GenerateOptions(
                                                t('piece.list.recycled', {returnObjects: true}),
                                                t('piece.list.recycled', {returnObjects: true, lng: 'fr'}),
                                                props.premium,
                                                true
                                            )
                                        }
                                    </ChoiceInput>
                                </div>

                                {/*nb element considéré*/}
                                {/*<div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.elements_considered')}</label>
                                    <input type={'number'} min={0} value={piece.numberOfPieceByRecipe}
                                           style={{width: "200px"}}
                                           onChange={(e) => setPiece({...piece, ...{numberOfPieceByRecipe: e.target.value}})}
                                    />
                                </div>*/}

                                {/*surface total*/}
                                <div className={'mb-2'}>
                                    <label
                                        className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.total_area')}</label>
                                    <input type={'number'} min={0} value={piece.totalArea} style={{width: "200px"}}
                                           onChange={(e) => setPiece({...piece, ...{totalArea: e.target.value}})}
                                    />
                                </div>


                            </div>


                        </div>


                        <div>
                            <AddRecipeStandist
                                piece={piece}
                                setPiece={setPiece}
                            />
                        </div>


                        {/*footer*/}
                        <div
                            className="flex items-center mt-10 justify-center p-6 border-t border-solid border-slate-200 rounded-b">


                            <button
                                className="text-red-500 font-bold  px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalPiece(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className=" btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    savePiece()
                                    setPiece(emptyObjectPiece)
                                    props.setModalPiece(false)
                                }}
                            >
                                {changeLabelButton()}
                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

function AddRecipeCaterer(props) {

    const {user} = useSelector(state => state.security)
    const [ingredientList, setIngredientList] = useState([]);
    const [t] = useTranslation('common')
    const [onlyMyIngredients, setOnlyMyIngredients] = useState(true);

    useEffect(async () => {

        const r = await getAllIngredients(user.token);
        setIngredientList(r?.data?.ingredientList);

    }, [])


    const getValue = (ingredient) => {

        let i = props?.piece?.recipes?.filter(element => element.ingredient[0]?.id === ingredient.id);

        return i[0] ? i[0].qty : null;
    }

    const setQtyInRecipe = (ingredient, qty) => {


        const ing = props?.piece?.recipes?.filter(element => element.ingredient[0]?.id === ingredient.id);

        if (ing && ing.length !== 0) {

            ing[0].qty = qty

        } else {

            props.piece.recipes.push({qty: parseInt(qty), ingredient: [ingredient]});
        }

        props.setPiece({...props.piece});

    }


    const categoryIngredient = [
        {name:"Epicerie salée"},
        {name:"Fruits/Légumes/Légumineuses",tooltip:t('piece.tooltip.vegetables_and_fruits')},
        {name:"Boulangerie/Pâtisserie"},
        {name:"BOF/Produits laitiers/Matières grasses"},
        {name:"Produits de la Mer"},
        {name:"Viandes/Charcuterie"},
        {name:"Boissons"},
        {name:"Epicerie sucrée"},
        {name:"Alternatives végétales"},
        {name:"custom"}
    ];

    const hiddenIngredients = [];


    return (
        <>
            <p className={'text-black font-semibold text-center my-6'}>{t('piece.ingredient.title')}</p>

            {categoryIngredient.map((cat) => {
                if(!hiddenIngredients.includes(cat)) {
                    return <DisclosureCategoryIngredient
                        categoryName={cat.name}
                        tooltip={cat.tooltip}
                        ingredientList={ingredientList}
                        setQtyInRecipe={setQtyInRecipe}
                        getValue={getValue}

                    />
                }
            })}


        </>
    )

}

function AddRecipeStandist(props) {

    const {user} = useSelector(state => state.security)
    const [furnishingsList, setFurnishingsList] = useState([]);
    const [t] = useTranslation('common')
    useEffect(async () => {

        const r = await getAllFurniture(user.token);
        setFurnishingsList(r?.data?.furnishingsList);

    }, [])

    const catAvoidDefaultValue = ["Menuiserie/Structure","Tapisserie","Signalétique"];

    const getValue = (ingredient, key) => {

        let i = props?.piece?.recipeFurnishings?.filter(element => element.furnishing[0]?.id === ingredient.id);

        if(i && i[0]){
            return i[0][key];
        } else if(key === "reused"){
            if(!catAvoidDefaultValue.includes(ingredient.category)) {
                return t("furnishings.recycled.7", {lng: 'fr'});
            }
        }

        return null;


        //return i[0] ? i[0][key] : null;
    }

    const setDataInRecipe = (ingredient, key, value) => {

        const ing = props?.piece?.recipeFurnishings?.filter(element => element.furnishing[0]?.id === ingredient.id);

        if (ing && ing.length !== 0) {

            ing[0][key] = value

        } else {

            props.piece.recipeFurnishings.push({[key]: value, furnishing: [ingredient]});
        }

        props.setPiece({...props.piece});
    }


    const categoryIngredient = [
        'Menuiserie/Structure',
        'Tapisserie',
        'Mobilier',
        'Electricité',
        'Décoration florale',
        'Signalétique',
        'Vidéo/Son',
        'Matériel traiteur',
        //'Machines', -- hidden
        'Sécurité',
        'custom'

    ];



    return (
        <>
            <p className={'text-black font-semibold text-center my-6'}>{t('piece.furnishings.title')}</p>

            {categoryIngredient.map((cat) => {
                return <DisclosureCategoryFurnishings
                    categoryName={cat}
                    ingredientList={furnishingsList}
                    setDataInRecipe={setDataInRecipe}
                    getValue={getValue}

                />

            })}


        </>
    )

}

function DisclosureCategoryIngredient(props) {
    const [t] = useTranslation('common')


    const getTraduction = (category, ingredientName) => {


        if (category === 'custom') {
            return ingredientName;
        } else {

            return t('piece.ingredient.product.' + ingredientName);
        }


    }

    const displayIngredientName = (category, ingredientName) => {

        let termToDisplay = getTraduction(category, ingredientName);

        if (termToDisplay.length > 50) {

            return termToDisplay.slice(0, 50) + ('...');
        } else {
            return termToDisplay;

        }

    }
    return <>


        <Disclosure
            size="8xl"
            label={{
                text: t('piece.ingredient.category.' + props.categoryName.trim()),
                color: "white",
            }}
            MoreclassName={"flex"}
            tooltip={props.tooltip}
        >
            {/* Afficher ici un message quand aucun ingrédient n'est présent (pareil pour standists & items) */}
            {props?.ingredientList?.length !== 0 && props.ingredientList?.filter(ingredient => ingredient.category.trim() === props.categoryName).map((ingredient, i) => {
                return (
                    <div>
                        {i === 0 && ingredient.category === 'Boissons' && ingredient.category !== 'custom' &&
                            <div className={'flex justify-end'}>
                                <p className={'blancCasse inline-block font-semibold  mr-48 mb-4  text-right'}>{t('piece.ingredient.liters')}</p>
                            </div>
                        }
                        {i === 0 && ingredient.category !== 'Boissons' && ingredient.category !== 'custom' &&
                            <div className={'flex justify-end'}>
                                <p className={'blancCasse inline-block font-semibold  mr-48 mb-4  text-right'}>{t('piece.ingredient.quantity')}</p>
                            </div>
                        }
                        <div className={'flex mb-2'}>
                            <label
                                title={getTraduction(props.categoryName, ingredient.name)}
                                className={'inline-block w-1/2 mr-4 mt-1  text-right'}>{displayIngredientName(props.categoryName, ingredient.name)}</label>
                            <div className={'mb-2'}>
                                <input type={'number'} min='0' style={{width: "230px", height: '30px'}}
                                       onChange={(e) => props.setQtyInRecipe(ingredient, e.target.value)}
                                       value={props.getValue(ingredient)}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
        </Disclosure>


    </>
}

function DisclosureCategoryFurnishings(props) {
    const [t] = useTranslation('common')
    const hiddenFurnishing = [
        "Béton (kg)", "Chaise en acier (nb)","Chaise en plastique (nb)","Table en acier (nb)","Table en plastique (nb)",
        "Coffret électrique gros (nb)", "Coffret électrique petit (nb)", "Groupe électrogène (puissance - kW)"
    ];

    const getTraduction = (category, ingredientName) => {


        if (category === 'custom') {
            return ingredientName;
        } else {

            return t('piece.furnishings.product.' + ingredientName);
        }


    }

    const displayIngredientName = (category, ingredientName) => {

        let termToDisplay = getTraduction(category, ingredientName);

        if (termToDisplay.length > 50) {

            return termToDisplay.slice(0, 50) + ('...');
        } else {
            return termToDisplay;

        }

    }

    return <>


        <Disclosure
            size="8xl"
            label={{
                text: t('piece.furnishings.category.' + props.categoryName.trim()),
                color: "white",
            }}
            MoreclassName={"flex"}
        >

            {props?.ingredientList?.length !== 0 &&
                <div className={'flex'}>
                    <p className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center '}
                       style={{width: "200px", marginRight: '5px'}}></p>
                    <p className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center'}
                       style={{width: "200px", marginRight: '5px'}}>
                            <span data-tooltip-id="quantity-tooltip">
                            {t('piece.furnishings.quantity')}
                                <FontAwesomeIcon className={'ml-2 text-base text-[#fdc96e]'} icon={faCircleInfo} />
                            </span>
                    </p>
                    <Tooltip id="quantity-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                        <span>{t('catalog.standist.space.quantity')}</span>
                    </Tooltip>
                    <p className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center '}
                       style={{width: "200px", marginRight: '5px'}}>{t('piece.furnishings.percentage_reused')}</p>
                    <p data-tooltip-id="percentRecycledPerCategory-tooltip" className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center '} style={{
                        width: "200px",
                        marginRight: '5px',
                        marginLeft: '15px'
                    }}>
                        {t('piece.furnishings.nbr_reused')}
                        <FontAwesomeIcon className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                    </p>
                    <Tooltip id="percentRecycledPerCategory-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                        <div className="flex flex-col">
                            <span>{t('catalog.standist.space.percentRecycledPerCategoryA')}</span>
                            <span>{t('catalog.standist.space.percentRecycledPerCategoryB')}</span>
                        </div>
                    </Tooltip>


                </div>
            }
            {props?.ingredientList?.length !== 0 && props.ingredientList?.filter(ingredient => ingredient.category.trim() === props.categoryName).map((ingredient) => {
                if(!hiddenFurnishing.includes(ingredient.name)) {
                    return <div className={'flex mb-2'}>
                        <label
                            title={getTraduction(props.categoryName, ingredient.name)}
                            className={'inline-block w-1/2 mr-4 mt-1  text-right'}>{displayIngredientName(props.categoryName, ingredient.name)}
                        </label>

                        <div className={'mb-2'}>
                            <input type={'number'} min='0' style={{width: "200px", height: '40px', marginRight: '5px'}}
                                   onChange={(e) => props.setDataInRecipe(ingredient, 'qty', e.target.value)}
                                   value={props.getValue(ingredient, 'qty')}
                            />
                        </div>

                        <div className={'mb-2'}>
                            <ChoiceInput
                                name="reused"
                                style={{width: "200px", height: '40px', marginRight: '5px'}}
                                value={props.getValue(ingredient, 'reused')}
                                textColorClass={"select-text-color-black"}
                                onChange={(e) => props.setDataInRecipe(ingredient, 'reused', e.target.value)}
                                className={'force-text-black'}
                            >

                                {GenerateOptions(
                                    t('furnishings.recycled', {returnObjects: true}),
                                    t('furnishings.recycled', {returnObjects: true, lng: 'fr'}),
                                    props.premium,
                                    true
                                )}

                            </ChoiceInput>
                        </div>

                        <div className={'mb-2'}>
                            <input type={'number'} min='0' name={'reusedNumber'}
                                   style={{width: "200px", height: '40px', marginRight: '5px'}}
                                   onChange={(e) => {
                                       const value = e.target.value;
                                       props.setDataInRecipe(ingredient, 'reusedNumber', value === '' ? null : value);
                                   }}
                                   value={props.getValue(ingredient, 'reusedNumber')}
                            />
                        </div>

                    </div>;
                }
            })}
        </Disclosure>


    </>
}

function DeleteModalPiece(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [piece, setPiece] = useState([])

    useEffect(() => {

        setPiece(props.piece);
    }, [])


    const removePiece = async () => {
        try {
            await deletePiece(user.token, piece);
            toast.success("Supprimé", TOAST_CONFIG)
            props.reload(Date.now());
        } catch (error) {
            console.error("Error encountered:", error)
            if (error.status === 409) {
                toast.error(t('eventList.delete_piece.toasts.piece_used_in_event'), TOAST_CONFIG);
            } else {
                toast.error(error.message || t('eventList.delete_piece.toasts.random_error'), TOAST_CONFIG);
            }
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <div className="relative p-6 flex-auto blancCasse font-bold">
                            {t("piece.delete",{value:props.piece.name})}
                        </div>


                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDeletePiece(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>
                            <button
                                className="btn-create"
                                type="button"
                                onClick={(e) => {
                                    removePiece();
                                    props.setModalDeletePiece(false);

                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}

function ModalTypology(props) {


    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [type, setType] = useState(emptyObjectType)

    useEffect(() => {
        if (props.piece) {
            setType(props.piece);
        }

    }, [])


    const saveType = async () => {

        if (type.id) {
            await updateType(user.token, type);
        } else {
            await createType(user.token, type);
        }

        props.reload(Date.now());
    }


    return (
        <>
            <div
                className="justify-center  flex  mt-80 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">


                        <h1 className={'blancCasse text-center mb-8'}>Ajouter une pièce</h1>
                        {/*body*/}

                        <div className="relative p-6 flex-auto">


                            <div>
                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2'}>name</label>
                                    <input type={'text'} value={type.name} style={{width: "200px"}}
                                           onChange={(e) => setType({...type, ...{name: e.target.value}})}
                                    />
                                </div>

                            </div>


                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                            <button
                                className="blancCasse  font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    saveType()
                                    props.setModalType(false)
                                }}
                            >
                                {t('catalogue.modal.save')}
                            </button>

                            <button
                                className="text-red-500 font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalType(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}

function DeleteModalType(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [type, setType] = useState([])

    useEffect(() => {

        if (props.piece) {
            setType(props.piece);
        }
    }, [])


    const handleDeleteType = async () => {
        const r = await deleteType(user.token, type.id);
        if (r.data.status === 'error') {
            toast.error(r.data.data, TOAST_CONFIG);
        } else {
            toast.success("Supprimé", TOAST_CONFIG)
        }
        props.reload(Date.now());
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}
                        <div className="relative p-6 flex-auto text-black">
                            Delete
                        </div>


                        {/*footer*/}
                        <div
                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-black font-bold uppercase text-sm px-2 py-3  hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    handleDeleteType();
                                    props.setModalDeletePiece(false);

                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                            <button
                                className="text-red-500 font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDeletePiece(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

function ModalAddIngredient(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [ingredient, setIngredient] = useState({})

    const saveIngredient = async (ingredient) => {
        if (!ingredient.name) {
            toast.error(t('ingredients.create_error'))
        } else {
            //nouvel objet qui reprend l'objet ingredient et vérifie si kgCO2eBykg est défini
            const newIngredient = {
                ...ingredient,
                kgCO2eBykg: ingredient.kgCO2eBykg ? ingredient.kgCO2eBykg : 1
            };

            //UPDATE
            if (newIngredient.id) {
                await updateIngredient(user.token, newIngredient)
                props.setModalIngredient(false);
                props.setIngredients(prevState => {
                    const updatedIngredients = prevState.map(item => item.id === newIngredient.id ? newIngredient : item);
                    return updatedIngredients;
                });
                toast.success(t('ingredients.maj'))
            } else {
                //CREATE
                await createIngredient(user.token, newIngredient)
                props.setModalIngredient(false);

                toast.success(t('ingredients.create'))
            }

            props.setReload(Date.now());


        }
    }

    useEffect(() => {
        if (props.selectedIngredient) {

            if (!props.selectedIngredient.unite) {
                props.selectedIngredient.unite = 'kg';
            }

            setIngredient(props.selectedIngredient)
        }
    }, [])


    const generateLabelButtonSave = () => {

        if (ingredient.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');

        }

    };

    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex mt-20 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalIngredient(false)}>
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{t('ingredients.add')}</h1>

                        <div className="relative p-6 flex-auto justify-center flex">
                            <div>
                                <div className={'flex mb-5'}>
                                    <div className={'mr-5'}>
                                        <label className={'inline-block mr-2'}>{t('ingredients.name')}</label>
                                        <input type={'text'}
                                               className={'w-72'}
                                               defaultValue={ingredient.name}
                                               onChange={(e) => setIngredient({...ingredient, ...{name: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label className={'inline-block mr-2'}>{t('ingredients.unite')}</label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "auto"}}
                                            value={ingredient.unite}
                                            textColorClass={"select-text-color-black"}
                                            onChange={(e) => setIngredient({...ingredient, ...{unite: e.target.value}})}

                                            className={'force-text-black'}>
                                            <option value={'kg'}>{t('ingredients.kg')}</option>
                                            <option value={'litre'}>{t('ingredients.litre')}</option>
                                            <option value={'unite'}>{t('ingredients.unite')}</option>
                                        </ChoiceInput>
                                    </div>
                                </div>

                                <div className={'mb-2 flex items-center'}>
                                    <label className={'mr-2'}>{t('ingredients.ef')}</label>
                                    <input type={'number'}
                                           className={'w-44 mr-5'}
                                           defaultValue={ingredient.kgCO2eBykg}
                                           min={0}
                                           onChange={(e) => setIngredient({...ingredient, ...{kgCO2eBykg: e.target.value}})}
                                    />
                                    <label
                                        className={'inline-block mr-2'}>kgCO2e/ {t('ingredients.' + ingredient.unite)}</label>
                                </div>

                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">

                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    props.setModalIngredient(false)
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveIngredient(ingredient);
                                }}
                            >
                                {generateLabelButtonSave()}

                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function ModalAddMenu(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [piecesInSelect, setPiecesInSelect] = useState([])
    const [piecesToDisplay, setPiecesToDisplay] = useState([])
    const [menu, setMenu] = useState({})


    const saveMenu = async () => {
        let tempMenu = {...menu}
        if(props.selectedMenu.name) {
            const update = await updateMenuApi(user.token, tempMenu);
        } else {
            const save = await saveMenuApi(user.token, tempMenu);
        }
        toast.success(t('menu.maj'));
        props.setModalMenu(false);
        props.setReload(Date.now());
        props.setSelectedMenu({})
    }

    useEffect(() => {
        setMenu(props.selectedMenu)
    },[])

    // this is made to homogenize data format, to display & to select
    useEffect(() => {
        const initialPiecesInSelect = props.paginationPieces
        const transformedPiecesInSelect = initialPiecesInSelect.map((piece) => ({id: piece.id, name: piece.name}))
        let filteredPiecesInSelect = transformedPiecesInSelect;
        
        if (menu.pieces) {
            filteredPiecesInSelect = transformedPiecesInSelect.filter((transformedPiece) =>
                !menu.pieces.some((selectedPiece) =>
                    selectedPiece.pieces[0].id === transformedPiece.id
                )
            );
            
            let tempPiecesToDisplay = [...piecesToDisplay]
            tempPiecesToDisplay = menu.pieces.map(piece => {
                return {number:piece.number, name:piece.pieces[0].name, id:piece.pieces[0].id}
            })
            setPiecesToDisplay(tempPiecesToDisplay)
        }
        
        setPiecesInSelect(filteredPiecesInSelect)
    },[menu])

    // Delete a piece from menu
    function removePiece(pieceId) {
        let tempMenu = {...menu};
        tempMenu.pieces = tempMenu.pieces.filter(piece => 
            !piece.pieces.some(subPiece => subPiece.id === pieceId)
        );
        setMenu(tempMenu);
    }

    // Change number of pieces in menu
    function handleChangeMenu(value, pieceId) {
        let tempMenu = { ...menu };
    
        tempMenu.pieces = tempMenu.pieces.map(piece => {
            const containsTargetId = piece.pieces.some(subPiece => subPiece.id === pieceId);

            if (containsTargetId) {
                return { ...piece, number: Number(value) };
            }

            return piece;
        });
    
        setMenu(tempMenu);
    }

    // Add a piece in menu
    function addPiece(selectedId) {
        let tempMenu = { ...menu };
        const selectedPiece = piecesInSelect.find(piece => piece.id === selectedId);

        const newPiece = {
            number: 0, 
            pieces: [selectedPiece] 
        };
        // if there's already pieces in menu.pieces

        if (Array.isArray(tempMenu.pieces)) {
            tempMenu.pieces.push(newPiece);
        } else {
            tempMenu.pieces = [newPiece];
        }

        setMenu(tempMenu);
    }


    const generateLabelButtonSave = () => {
        if (menu.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');
        }
    };

    return (
        <>
            <div className="fixed inset-0 opacity-25 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalMenu(false)}>
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-[85vh] 2xl:min-h-[50vh] 2xl:max-h-[85vh] 2xl:h-fit bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <h1 className={'blancCasse text-center my-4 2xl:my-6 text-2xl'}>{t('menu.add')}</h1>

                        <div className="relative px-6 flex-auto justify-center overflow-y-auto flex">
                            <div className={'w-full'}>
                                <div className={'flex my-2 2xl:my-4'}>
                                    <div className={'flex-row items-center justify-center w-full'}>
                                        <label className={'inline-block mr-4'}>{t("menu.name")}</label>
                                        <input type={'text'}
                                               className={'w-4/6 h-3/4 placeholder-gray-500'}
                                               defaultValue={menu.name || ''}
                                               onChange={(e) => setMenu({...menu, ...{name: e.target.value}})}
                                               placeholder={""}
                                        />
                                    </div>
                                </div>
                                <div className={"flex flex-row text-black"}>
                                    <p className={'blancCasse inline-block mr-4'}>{t("menu.add_piece")}</p>
                                    <div >
                                        <SearchableInput
                                            style={{padding: "0.25rem 0.5rem"}}
                                            styleClass={"menuSearchInput"}
                                            bg_sup={get_class_color("travel-blue", props.premium)}
                                            options={piecesInSelect}
                                            onSelectChange={(selectedOptionId) => {
                                                addPiece(selectedOptionId);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={"py-2 2xl:py-4"}>
                                    {piecesToDisplay.length > 0 &&
                                        <table>
                                            <tr className={'blancCasse'}>
                                                <th></th>
                                                <th>Nb {t('menu.pieces')}</th>
                                            </tr>

                                            {piecesToDisplay.map((piece, k) => {
                                                return (
                                                    <tr key={k}>
                                                        <td className={'w-56 truncate blancCasse'}>{piece.name}</td>
                                                        <td>
                                                            <input className={'w-24 mx-4'}  type="number"
                                                                    value = {piece.number}
                                                                    onChange={(e) => handleChangeMenu(e.target.value, piece.id)}
                                                                    min="0"
                                                            />
                                                        </td>
                                                        <td>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer blancCasse yellowHover"
                                                                            onClick={() => {
                                                                                removePiece(piece.id);
                                                                            }} icon={faTrash}/>
                                                        </td>

                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    }
                                </div>

                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center border-t border-solid border-slate-200 justify-center px-6 py-4 2xl:py-6 rounded-b">

                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    props.setModalMenu(false)
                                    // setDontUpdateNow(false);
                                    props.setSelectedMenu({})
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveMenu();
                                }}
                            >
                                {generateLabelButtonSave()}

                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function DeleteModalIngredientOrFurniture(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [ingredient, setIngredient] = useState([])

    useEffect(() => {

        setIngredient(props.selectedIngredient);
    }, [])


    const removeIngredient = async () => {
        if (authorization(user, 'ROLE_CATERER')) {
            await deleteIngredient(user.token, ingredient);
            props.reload(Date.now());
            toast.success(t("ingredients.delete"))
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            await deleteFurniture(user.token, ingredient);
            props.reload(Date.now());
            toast.success(t("furniture.delete"))
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}
                        <div className="relative p-6 flex-auto blancCasse font-bold">
                            {authorization(user, 'ROLE_CATERER') &&
                                (t('ingredients.delete_title') + " ?")
                            }{authorization(user, 'ROLE_STANDIST') &&
                            (t('furniture.delete_title') + " ?")
                        }
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDelete(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>
                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    removeIngredient();
                                    props.setModalDelete(false);
                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}

function DeleteModalMenu(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')

    useEffect(() => {

    }, [])

    const removeMenu = async () => {
        try {
            await deleteMenuApi(user.token, props.selectedMenu);
            props.reload(Date.now());
            toast.success(t("menu.delete"));
            props.setSelectedMenu({});
        } catch (error) {
            if (error.response) {
                toast.error(t('menu.menuInEvent'));
            } else {
                // Gérer les erreurs de réseau ou autres erreurs non liées à la réponse HTTP
                toast.error("Network Error");
            }
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}
                        <div className="relative p-6 flex-auto blancCasse font-bold">
                            {(t('menu.delete_title') + " ?")}
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDelete(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>
                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    removeMenu();
                                    props.setModalDelete(false);
                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}

function ModalAddFurniture(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [furniture, setFurniture] = useState({})

    const saveFurniture = async (furniture) => {
        if (!furniture.name) {
            toast.error(t('furniture.create_error'))
        } else {
            //nouvel objet qui reprend l'objet furniture et vérifie si efRecycled et ef sont définis
            const newFurniture = {
                ...furniture,
                ef: furniture.ef ? furniture.ef : 1,
                // efRecycled: furniture.efRecycled ? furniture.efRecycled : 1
                efRecycled: furniture.ef ? furniture.ef : 1
            };

            //UPDATE
            if (newFurniture.id) {
                await updateFurniture(user.token, newFurniture)
                props.setModalFurniture(false);
                props.setFurniture(prevState => {
                    const updatedFurniture = prevState.map(item => item.id === newFurniture.id ? newFurniture : item);
                    return updatedFurniture;
                });
                toast.success(t('furniture.maj'))
            } else {
                //CREATE
                await createFurniture(user.token, newFurniture)
                props.setModalFurniture(false);

                toast.success(t('furniture.create'))
            }

            props.setReload(Date.now());


        }
    }

    useEffect(() => {
        if (props.selectedFurniture) {

            if (!props.selectedFurniture.unite) {
                props.selectedFurniture.unite = 'kg';
            }

            setFurniture(props.selectedFurniture)
        }
    }, [])


    const generateLabelButtonSave = () => {

        if (furniture.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');

        }

    };

    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex mt-20 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalFurniture(false)}
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{t('catalogue.addItemModal.title')}</h1>

                        <div className="relative p-6 flex-auto justify-center flex">
                            <div>
                                <div className={'flex mb-5'}>
                                    <div className={'mr-5'}>
                                        <label className={'inline-block mr-2'}>{t('catalogue.addItemModal.itemName')}</label>
                                        <input type={'text'}
                                               className={'w-72'}
                                               defaultValue={furniture.name}
                                               onChange={(e) => setFurniture({...furniture, ...{name: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label className={'inline-block mr-2'}>{t('catalogue.addItemModal.unity')}</label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "auto"}}
                                            value={furniture.unite}
                                            textColorClass={"select-text-color-black"}
                                            onChange={(e) => setFurniture({...furniture, ...{unite: e.target.value}})}

                                            className={'force-text-black'}>
                                            <option value={'kg'}>{t('ingredients.kg')}</option>
                                            <option value={'m²'}>m²</option>
                                            <option value={'unite'}>{t('ingredients.unite')}</option>
                                            <option value={'ml'}>{t('ingredients.lm')}</option>
                                        </ChoiceInput>
                                    </div>
                                </div>

                                <div className={'mb-5 flex items-center'}>
                                    <label className={'mr-2'} style={{width: '9rem'}}>{t('catalogue.addItemModal.EF')}</label>
                                    <input type={'number'}
                                           name={'ef'}
                                           className={'w-44 mr-5'}
                                           defaultValue={furniture.ef}
                                           min={0}
                                           onChange={(e) => setFurniture({...furniture, ...{ef: e.target.value}})}
                                    />
                                    <label
                                        className={'inline-block mr-2'}>kgCO2e/ {furniture.unite === 'm²' ? 'm²' : t('ingredients.' + furniture.unite)}</label>
                                </div>

                                {/* <div className={'mb-5 flex items-center'}>
                                    <label className={'mr-2'} style={{width: '9rem'}}>FE recyclé</label>
                                    <input type={'number'}
                                           name={'ef_recycled'}
                                           className={'w-44 mr-5'}
                                           defaultValue={furniture.efRecycled}
                                           min={0}
                                           onChange={(e) => setFurniture({...furniture, ...{efRecycled: e.target.value}})}
                                    />
                                    <label
                                        className={'inline-block mr-2'}>kgCO2e/ {furniture.unite === 'm²' ? 'm²' : t('ingredients.' + furniture.unite)}</label>
                                </div> */}

                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">

                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    props.setModalFurniture(false)
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveFurniture(furniture);
                                }}
                            >
                                {generateLabelButtonSave()}

                            </button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
