import { Link } from "react-router-dom"
import {useSelector} from "react-redux";
import arrowDiag from "../../../images/fleche_diagonale.png";



export const ArrowTitle = () => {

    const customization = useSelector(state => state.customization)

    return <><img src={arrowDiag} className={'h-8 w-8 inline-block mx-4 align-baseline'}/></>
}