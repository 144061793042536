export const CALCULATOR_SETTING = "CALCULATOR_SETTING"
export const CALCULATOR_EMISSION = "CALCULATOR_EMISSION"
export const CALCULATOR_PHYSICAL= "CALCULATOR_EMISSION"
export const CALCULATOR_DIGITAL = "CALCULATOR_DIGITAL"
export const CALCULATOR_PROVIDER = "CALCULATOR_PROVIDER"
export const EDIT_CALCULATOR = "EDIT_CALCULATOR"
export const CLEAR_CALCULATEUR = "CLEAR_CALCULATEUR"
export const SET_CALCULATOR_VAL = "SET_CALCULATOR_VAL"
export const BACKGROUND_COLOR = "BACKGROUND_COLOR"
export const BACKGROUND_BOX = "BACKGROUND_BOX"
export const TITLE_COLOR = "TITLE_COLOR"
export const DEFAULT_IMG_PROFIL = "/images/users/defaultProfilePic.png"