import React from "react"
import { useSelector } from "react-redux"
import {Route, Redirect, useHistory} from "react-router-dom"
import {CATALOGUE_ROUTE, EVENT_LIST_PATH, LOGIN_ROUTE} from "../../constants/appRoute"

export function IsUserRedirect({loggedInPath, children, ...restProps }) {
    const {user} = useSelector(state => state.security)
    const history = useHistory()

    return (
        <Route
            {...restProps}
            render={() => {
                if (!user) {
                    return children
                }

                if (user) {
                    if(user.roles.includes('ROLE_ORGANIZER') || user.roles.includes('ROLE_COMPANY') ||user.roles.includes('ROLE_PROJECT_MANAGER')) {
                        history.push(EVENT_LIST_PATH)
                        // return <Redirect to={{ EVENT_LIST_PATH }} />
                    } else {
                        //si l'utilisateur a déja selectionné ou se rendre lors de sa connexion on le redirige dessus
                        // sinon il ira vers la page catalogue
                        if(localStorage.getItem('DIRECTION_CHOSE')) {
                            history.push(localStorage.getItem('DIRECTION_CHOSE'))
                        } else {
                            history.push(CATALOGUE_ROUTE)
                        }
                        // return <Redirect to={{ CATALOGUE_ROUTE }} />
                    }
                }

                return null
            }}
        />
    )
}

export function ProtectedRoute({children, ...restProps }) {
    const {user} = useSelector(state => state.security)
    return (
        <Route
            {...restProps}
            render={({ location }) => {
                if (user) {
                    return children
                }

                if (!user) {
                    return (
                        <Redirect
                            to={{
                                pathname: LOGIN_ROUTE,
                                state: { from: location },
                            }}
                        />
                    )
                }
                return null
            }}
        />
    )
}
