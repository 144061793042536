import JwtDecode from 'jwt-decode'
import {LOCAL_STORAGE_USER} from "../constants/securityConstant";

export const loggedUser = () => {
    const token = localStorage.getItem(LOCAL_STORAGE_USER)

    if (token) {
        const paresedToken =  JSON.parse(token)
        try {
            const decodedToken = JwtDecode(paresedToken.token)
            if (decodedToken.exp * 1000 < Date.now()) {
                localStorage.removeItem("AuthToken")
                return null
            }
            return {...decodedToken, token : paresedToken.token}
        } catch (e) {
            return null
        }
    }
    return null
}
