import {resolve} from "../../hook/resolver";
import axios from "axios";
import {CREATE_MENU, DELETE_MENU, UPDATE_MENU} from "../../constants/apiService";

export const saveMenuApi = async (token, data) => {
    return await resolve(axios.post(CREATE_MENU, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const updateMenuApi = async (token, data) => {
    return await resolve(axios.put(UPDATE_MENU, data, {
        headers: {Authorization: `Bearer ${token}`}
    })).then(r => r.data)
}

export const deleteMenuApi = async (token, data) => {
    try {
        const response = await axios.delete(DELETE_MENU + "/" + data.id, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}
