export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE"
export const FORGOT_SENT = "FORGOT_SENT"

export const REGISTER_REQUEST = "REGISTER_REQUEST"

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILED = "LOGOUT_FAILED"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"

export const LOCAL_STORAGE_USER = "LOCAL_STORAGE_USER"