import React, {useState} from 'react'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {CircleLoader} from "react-spinners";
import {useSelector} from "react-redux";

const override = css`
  display: block;
  margin: 0 auto;
   border-color: white;
`;

export default function Loading({loading}) {
    const customization =  useSelector(state => state.customization)
    return (
        <div  style={{backgroundColor: customization.backgroundColor}}
            className="h-screen bg-green-light flex items-center justify-center">
            <CircleLoader  color={"#ffffff"} loading={loading} size={150} />
        </div>
    )
}