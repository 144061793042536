import axios from "axios";
import {resolve} from "../../hook/resolver";
import {
    CHECK_IF_SUB,
    CHECK_PASSWORD,
    GET_BACKGROUNDCOLOR_LOGO,
    GET_INVOICES, GET_MAX_INVITATIONS,
    POST_BACKGROUNDCOLOR,
    POST_PROFILE_IMG,
    USER_API
} from "../../constants/apiService";

export const getUser = async (id, token) => {
    return await resolve(axios.get(USER_API + `/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const updateUser = async (id, data, token) => {
    return await resolve(axios.put(USER_API + `/${id}`, data, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}


export const checkUserSub = async (token) => {
    return await resolve(axios.get(CHECK_IF_SUB, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

export const getInvoices = async (token) => {
    return await resolve(axios.get(GET_INVOICES, {
        headers: {Authorization: `Bearer ${token}`}
    }).then(r => r.data))
}

// export const getUserMaxInvitations = async (token) => {
//     return await resolve(axios.get(GET_MAX_INVITATIONS, {
//         headers: {Authorization: `Bearer ${token}`}
//     }).then(r => r.data))
// }

export const checkOldpassword = async (token, oldPassword) => {

    return await resolve(axios.post(CHECK_PASSWORD,oldPassword,{headers: {Authorization: `Bearer ${token}`}} ).then(r => r.data))
}

export const postProfileImg = async (token, formdata) => {

    return await resolve(axios.post(POST_PROFILE_IMG,formdata,{headers: {Authorization: `Bearer ${token}`}} ).then(r => r.data))
}

export const postBackgroundColor = async (token, backgroundColor) => {

    return await resolve(axios.post(POST_BACKGROUNDCOLOR, {backgroundColor},{headers: {Authorization: `Bearer ${token}`}} ).then(r => r.data))
}

export const getBackgroundColorLogo = async (token) => {

    return await resolve(axios.get(GET_BACKGROUNDCOLOR_LOGO,{headers: {Authorization: `Bearer ${token}`}} ).then(r => r.data))
}
