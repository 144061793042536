import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {calculatorReducer} from './js/reducer/calculatorReducer';
import {languageReducer} from './js/reducer/languageReducer';
import {securityReducer} from './js/reducer/securityReducer';
import {loggedUser} from "./js/hook/authDecoder";
import {customizationReducer} from "./js/reducer/customizationReducer";
import {eLearningReducer} from "./js/reducer/eLearningReducer";

const initialState = {
    language: {
        language: window.localStorage.getItem("language") || "en"
    },
    security: {
        user: loggedUser(),
        errors: null,
        message: null
    },
    calculator: {
        settings: {
            data: {},
            emissions: {},
            name: null
        },
        physical: {
            data: {}
        },
        digital: {},
        provider: {},
        editCalculator: null
    },
    customization: {
        backgroundColor: '#10384E'
    },
    eLearningAnswers: {
        answers: {Coucou:"Coucou"}
    }
};

const reducer = combineReducers({
    language: languageReducer,
    security: securityReducer,
    calculator: calculatorReducer,
    customization: customizationReducer,
    eLearningAnswers: eLearningReducer
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;