import React from "react";
import {useTranslation} from "react-i18next";

function Pagination({currentPage, totalPages, onPageChange, withButtons, setCurrentPage}) {
    const [t] = useTranslation('common');

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }

    };

    return (
        <div className={"flex space-x-2 absolute bottom-1.5 w-full justify-center"}>
            {withButtons &&
                <button className={"bg-black p-2 btn-create"} onClick={goToPreviousPage}
                        /*hidden={currentPage === 1}*/>{t('providerList.previous')}
                </button>
            }

            <div className="flex justify-center items-center" style={{ width: "150px", color: "#FFD26E" }}>
                {currentPage !== 1 && (
                    <span
                        onClick={() => onPageChange(1)}
                        className="mr-1 ml-1 cursor-pointer"
                    >
                        1
                    </span>
                )}
                {currentPage !== 1 && currentPage !== 2 && (
                    <span className="mr-1 ml-1">...</span>
                )}
                {currentPage !== 1 && currentPage !== 2 && (
                    <span
                        onClick={() => onPageChange(currentPage - 1)}
                        className="mr-1 ml-1 cursor-pointer"
                    >
                    {currentPage - 1}
                    </span>
                )}
                <span
                    className="mr-1 ml-1 flex justify-center items-center"
                    style={{
                        backgroundColor: "#FFD26E",
                        height: "20px",
                        width: "20px",
                        color: "black",
                    }}
                >
                {currentPage}
                </span>
                {currentPage !== totalPages && currentPage !== totalPages - 1 && (
                    <span
                        onClick={() => onPageChange(currentPage + 1)}
                        className="mr-1 ml-1 cursor-pointer"
                    >
                    {currentPage + 1}
                    </span>
                )}
                {currentPage !== totalPages && currentPage !== totalPages - 1 && (
                    <span className="mr-1 ml-1">...</span>
                )}
                {currentPage !== totalPages && (
                    <span
                        onClick={() => onPageChange(totalPages)}
                        className="mr-1 ml-1 cursor-pointer"
                    >
                    {totalPages}
                    </span>
                )}
            </div>
            {withButtons &&
                <button className={"bg-black p-2 btn-create"} onClick={goToNextPage}
                        /*hidden={currentPage === totalPages}*/>{t('providerList.next')}
                </button>
            }
        </div>
    );

}

export default Pagination