import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {getComplementaryColor, IniBackgroundColor} from "../../actions/backgroundColor";
import {ArrowTitle} from "../../components/shared/arrowTitle";
import {getAllIngredients, createIngredient, updateIngredient, deleteIngredient} from "../../utils/api/ingredients";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

export default function Ingredients(props) {
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const {user} = useSelector(state => state.security)
    const [ingredients, setIngredients] = useState([]);
    const [modalIngredient, setModalIngredient] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState({})
    const [modalDelete, setModalDelete] = useState(false);
    const [reload, setReload] = useState(null);
    const history = useHistory()

    const recipesIngredientsId = [];
    const pushRecipesIngredients = () => {
        for(const state of history.location.state){
            for(const recipe of state.recipes ){
                recipesIngredientsId.push(recipe.ingredient[0].id)
            }
        }
    }

    const openModalAddIngredient = () => {
        setModalIngredient(true)
    }


    useEffect(async () => {
        const r = await getAllIngredients(user.token);
        setIngredients(r.data);
    }, [reload])


    const openModalDelete = (ingredient) => {
        pushRecipesIngredients();
        if(recipesIngredientsId.includes(ingredient.id)){
            toast.error('Cet ingrédient est affecté à une recette')
        }else{
            setModalDelete(true)
        }
    }

    return (<>
        <div
            style={{backgroundColor: customization.backgroundColor}}
            className="bg-green-light flex flex-col items-center min-h-screen w-full px-5">
            <h2 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                className="py-3 font-title font-bold text-5xl "><ArrowTitle/>{t('ingredients.title')}</h2>

            <div>
                <button
                    onClick={() => {
                        setSelectedIngredient({})
                        openModalAddIngredient()
                    }}
                    className={'btn-create blancCasse text-lg border border-green-500 font-bold py-2 px-8 flex justify-center rounded'}>
                    {t('common:ingredients.add')}
                </button>
            </div>


            <div>
                {ingredients.length !== 0 && ingredients.map((ingredient) => {

                    return <p key={ingredient.id} className={'text-black'}>
                        {ingredient.name} - {ingredient?.kgCO2eBykg}

                        <FontAwesomeIcon className={'mx-3 mt-2 text-black pointer'} icon={faEdit} onClick={() => {
                            setSelectedIngredient(ingredient)
                            openModalAddIngredient()
                        }}/>
                        <FontAwesomeIcon className={'mx-3 mt-2 text-black pointer'} icon={faTrash} onClick={() => {
                            setSelectedIngredient(ingredient)
                            openModalDelete(ingredient)
                        }}/>

                    </p>
                })}
            </div>

            {modalIngredient &&
                <ModalAddIngredient
                    setModalIngredient={setModalIngredient}
                    setIngredients={setIngredients}
                    selectedIngredient={selectedIngredient}
                />
            }

            {modalDelete &&
                <DeleteModalIngredient
                    selectedIngredient={selectedIngredient}
                    setModalDelete={setModalDelete}
                    reload={setReload}
                />
            }

        </div>

    </>)
}


function ModalAddIngredient(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [ingredient, setIngredient] = useState({})

    const saveIngredient = async(ingredient) => {
        if(!ingredient.name){
            toast.error(t('ingredients.create_error'))
        }else {
            //nouvel objet qui reprend l'objet ingredient et vérifie si kgCO2eBykg est défini
            const newIngredient = {
                ...ingredient,
                kgCO2eBykg: ingredient.kgCO2eBykg ? ingredient.kgCO2eBykg : 1
            };

            //UPDATE
            if(newIngredient.id){
                await updateIngredient(user.token, newIngredient)
                props.setModalIngredient(false);
                props.setIngredients(prevState => {
                    const updatedIngredients = prevState.map(item => item.id === newIngredient.id ? newIngredient : item);
                    return updatedIngredients;
                });
                toast.success(t('ingredients.maj'))
            }else {
                //CREATE
                await createIngredient(user.token, newIngredient)
                props.setModalIngredient(false);
                props.setIngredients((prevState) => [...prevState, newIngredient]);
                toast.success(t('ingredients.create'))
            }
        }
    }

    useEffect(() => {
        if(props.selectedIngredient){
            setIngredient(props.selectedIngredient)
        }
    },[])

    return (
        <>
            <div className="justify-center items-center flex mt-20 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <div className="relative p-6 flex-auto">
                            <div>
                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2'}>{t('ingredients.name')}</label>
                                    <input type={'text'}
                                           style={{width: "200px"}}
                                           defaultValue={ingredient.name}
                                           onChange={(e) => setIngredient({...ingredient, ...{name: e.target.value}})}
                                    />
                                </div>

                                <div className={'mb-2'}>
                                    <label className={'inline-block w-1/2 mr-2'}>kgCO2e/kg</label>
                                    <input type={'number'}
                                           style={{width: "200px"}}
                                           defaultValue={ingredient.kgCO2eBykg}
                                           min={0}
                                           onChange={(e) => setIngredient({...ingredient, ...{kgCO2eBykg: e.target.value}})}
                                    />
                                </div>
                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">

                            <button
                                className="blancCasse  font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveIngredient(ingredient);
                                }}
                            >
                                {t('catalogue.modal.save')}
                            </button>

                            <button
                                className="text-red-500 font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={ () => {
                                    props.setModalIngredient(false)
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
)
}

function DeleteModalIngredient(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [ingredient, setIngredient] = useState([])

    useEffect(() => {

        setIngredient(props.selectedIngredient);
    }, [])


    const removeIngredient = async () => {

        await deleteIngredient(user.token, ingredient);
        props.reload(Date.now());
        toast.success(t("ingredients.delete"))
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}
                        <div className="relative p-6 flex-auto text-black">
                            {t('ingredients.delete_title') + ingredient.name + " ?"}
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-black font-bold uppercase text-sm px-2 py-3  hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    removeIngredient();
                                    props.setModalDelete(false);
                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                            <button
                                className="text-red-500 font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDelete(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}
